import Vue from 'vue'
import path from 'path'
import { icons } from '@/assets/vendor/mdi-svg.min'
import { GET, DELETE } from '@/utils/api/endpoints'
import { getKPISetting } from '@/utils/config/kpis'
import { populateElementImage } from '@/utils/helpers/rappid'
import throwBetterError from '@/utils/helpers/throwBetterError'
import typeOf from '@/utils/helpers/typeOf'
import i18n from '@/utils/plugins/i18n'

/** Transforms an array of objects into an object
 *
 * @param [array] {array}     - an array of objects
 *                            @example
 *                              [
 *                                { id: '1', prop: 'xD'},
 *                                { id: '2', prop: ':P'}
 *                              ]
 * @param [options] {object}  - optional params
 *
 * @returns                   - **Object**, (with specified keys)
 *                            - _default:_
 *                                - remove 'id' key from each inner object
 *                            - _options:_
 *                                - **key** (_default='id'_) --- can be set, to specify key to enumerate upon
 *                                - **value** (_default=undefined_) --- can be set, to specify the property used to populate each key's value
 *                                - **retainKey** (_default=false_) --- can be set to true, to not remove key
 *                            @example
 *                              // default
 *                              {
 *                                '1': { prop: 'xD'},
 *                                '2': { prop: ':P'}
 *                              }
 *                            @example
 *                              // declare { key: 'prop' }
 *                              {
 *                                'xD': { id: '1' },
 *                                ':P': { id: '2' }
 *                              }
 *                            @example
 *                              // declare { value: 'prop' }
 *                              {
 *                                '1': 'xD',
 *                                '2': ':P'
 *                              }
 *                            @example
 *                              // declare { retainKey: true }
 *                              {
 *                                '1': { id: '1', prop: 'xD'},
 *                                '2': { id: '2', prop: ':P'}
 *                              }
 *                            @example
 *                              // declare { key: 'prop', retainKey: true }
 *                              {
 *                                'xD': { id: '1', prop: 'xD'},
 *                                ':P': { id: '2', prop: ':P'}
 *                              }
 */
export function arrayToObject (array, { key = 'id', value, retainKey = false } = {}) {
  if (!Array.isArray(array)) return console.error(`[arrayToObject]: was not passed an array`, array)
  if (_.isEmpty(array)) return {}
  const keyIsValid = array.every((obj) => _.hasIn(obj, key))
  const valueIsValid = array.every((obj) => _.hasIn(obj, value))
  const valuePropProvided = !!value

  const problemWith = {
    array: {
      itemsAreNotObjects: !array.every((item) => typeOf(item) === 'object'),
    },
    value: {
      keyAndValueInvalid: valuePropProvided && !keyIsValid && !valueIsValid,
      valueInvalid: valuePropProvided && keyIsValid && !valueIsValid,
    },
    key: {
      keyInvalid: !keyIsValid,
    },
  }
  if (problemWith.array.itemsAreNotObjects) return console.error(`[arrayToObject]: array items are not all objects`, { array, key })
  if (problemWith.value.keyAndValueInvalid) return console.error(`[arrayToObject]: was not passed valid key and value params`, { array, key, value })
  if (problemWith.value.valueInvalid) return console.error(`[arrayToObject]: was not passed valid value`, { array, value })
  if (problemWith.key.keyInvalid) return console.error(`[arrayToObject]: not all items have the passed key`, { array, key })

  const newObj = {}
  for (const obj of array) {
    const objKey = obj[String(key)]
    newObj[objKey] = valuePropProvided ? obj[value] : { ...obj }
    if (!retainKey) delete newObj[objKey][key]
  }
  return newObj
}

/**
 * Checks store to see if item being requested (by id) has already been fetched from the api
 * - if it has, then use the vuex store version and do not make a second call to the api
 * @param {Object.list} list  - array of available items already on the store
 * @param {Object.id} id      - id of the item to check
 * @returns {Object} { cachedItem: Object, cached: Boolean, index: Number }
 */
export function alreadyFetched ({ list, id }) {
  const index = list.findIndex(({ canonical }) => canonical === id)
  const cached = _.has(list[index], 'schema')
  const cachedItem = cached ? list.find(({ canonical }) => canonical === id) : null
  return {
    cachedItem,
    cached,
    index,
  }
}

/** Find the duplicates within an array
 * ! Best used with primitives, as arrays and objects will not be parsed
 *
 * @param   {array} [array]   - any array (ideally of primitives)
 * @returns [array]           - an array of unique values, that appear more than once in the original array
 */
export function findDuplicates (array) {
  if (!Array.isArray(array)) {
    console.error(`[findDuplicates]: was not passed an array`, array)
    return []
  }
  const keyCount = _.countBy(array)
  if (Object.keys(keyCount).includes('[object Object]')) {
    console.warn(`[findDuplicates]: was passed an array containing an Object, of which is not parsed`, array)
  }
  return Object.keys(keyCount).filter((key) => keyCount[key] > 1)
}

/** Simple function to strip invalid empty properties from an object
 *
 * @param {object} [object]             any object; but optimally with all property values as objects
 * @param {regex} [.propsToRemove]      **Optional** Regex to match properties that should be removed
 *                                      - _Default_ null
 * @returns [object]                    a stripped object, with all keys that had invalid properties removed
 *                          @example [default] - removes empty/falsy properties
 *                            stripInvalidProperties({ a: {}, b: 123, c: { asd: 123 }, _d: 'abc', e: '', f: false, x: true })
                              // returns { b: 123, c: { asd: 123 }, d: 'abc', f: false }
 *
 *                          @example [propsToRemove = /(^_|x)/] - removes empty/falsy properties + props matching regex (begins with _ or is 'x')
 *                            stripInvalidProperties({ a: {}, b: 123, c: { asd: 123 }, _d: 'abc', e: '', f: false, x: true }, { propsToRemove: /(^_|x)/ })
                              // returns { b: 123, c: { asd: 123 } }
 */
export function stripInvalidProperties (obj, { propsToRemove = null } = {}) {
  const theObject = JSON.parse(JSON.stringify(obj))

  function stripProperties (object) {
    for (const key of Object.keys(object)) {
      if (propsToRemove && propsToRemove.test(key)) delete object[key]
    }

    for (const [key, value] of Object.entries(object)) {
      const isObject = typeOf(value) === 'object'
      const isArray = typeOf(value) === 'array'
      const isSet = isArray && _.every(value, _.isPlainObject)
      const hasChildren = isObject || isArray || isSet
      const deleteKey = () => {
        if (!hasChildren) return _.$isEmpty(object[key])
        if (isSet) return object[key].every((subObj) => _.every(Object.values(subObj), _.$isEmpty))
        if (isObject) return _.every(Object.values(object[key]), _.$isEmpty)
        if (isArray) return _.every(object[key], _.$isEmpty)
      }

      if (isArray) object[key] = object[key].filter((listItem) => !_.$isEmpty(listItem))
      if (isSet) object[key] = Object.keys(object[key]).map((objKey) => stripProperties(object[key][objKey]))
      if (deleteKey()) delete object[key]
      else if (hasChildren) stripProperties(object[key])
    }

    return object
  }

  return stripProperties(theObject)
}

/** Decode a base64url
 *
 * @see [RFC 4648]{@link https://tools.ietf.org/html/rfc4648#page-7}
 * @see [Wikipedia]({@link https://en.wikipedia.org/wiki/Base64}
 * Note the padding isn't required for decoding and atob works with base64
 * with padding and without
 *
 * @param {String} str  - The base64url string to decode
 * @returns {Object}    - A decoded object
 */
export function decodeJWT (str) {
  return JSON.parse(
    decodeURIComponent(
      atob(str
        .replace(/-/g, '+')
        .replace(/_/g, '/'))
        .replace(/(.)/g, (_m, char) => `%${char.charCodeAt(0).toString(16).toUpperCase().padStart(2, '0')}`),
    ),
  )
}

/** Format breadcrumbs in order to always display their label, if canonical is not provided
 *
 * @param {Object}      - An array of breadcrumbs [{ label, params, name }]
 */
const formatBreadcrumb = ({ params, name, label } = {}) => {
  return {
    label: label || params[Object.keys(params)[0]],
    ...(name ? { to: { name, params } } : {}),
  }
}

/** A helper function for breadcrumbs creation.
 *
 * @see [Github] {@link https://github.com/Suruat/vue-2-crumbs#using-parentslist}
 * vue-2-crumbs expects to receive an object like this:
 *
 * {
 *   label: this.postTitle,
 *   parentsList: [
 *     {
 *       to: {
 *         name: 'category',
 *         params: {
 *           catSlug: this.$route.params.catSlug
 *         }
 *      },
 *      label: this.categoryTitle
 *    }
 *   ]
 * }
 *
 *
 * @param {String} [pageName]     - (required) The component name, to help with debugging
 * @param {String} [label]        - Label for the current route, _default_ '...'
 *                                  '...' is useful to show in place whilst something is being fetched
 * @param {Array} [parents]       - An array of parent routes, _default_ []
 *                                @example
 *                                [
 *                                  {
 *                                    label: this.$t('routes.members'),
 *                                     name: 'members'
 *                                  },
 *                                  {
 *                                    params: { orgCanonical },
 *                                    name: 'organization'
 *                                  }
 *                                ]
 *
 * @returns {Object}              - A breadcrumbs Object that can be used by vue-2-crumbs library
 */
export function constructBreadcrumb (_pageName, label = '...', parents = []) {
  const MAX_PARENT_BREADCRUMBS = 3
  const formattedParents = _.map(parents, (breadcrumb) => formatBreadcrumb(breadcrumb))

  if (parents.length <= MAX_PARENT_BREADCRUMBS) {
    return { label, parentsList: formattedParents }
  }

  const dropdownItems = _.reverse(formattedParents.slice(MAX_PARENT_BREADCRUMBS - 1, parents.length - 1))
  const visibleParents = formattedParents.slice(0, MAX_PARENT_BREADCRUMBS - 1)

  const parentsList = [
    ...visibleParents,
    {
      ..._.last(formattedParents),
      ...{ utils: { dropdownItems } },
    },
  ]

  return { label, parentsList }
}

// ! Only exporting for mocking in tests
export function externalImageExists (imagePath) {
  return new Promise((resolve) => {
    const img = new Image()
    img.addEventListener('load', () => resolve(true))
    img.addEventListener('error', () => resolve(false))
    img.src = imagePath
  })
}

/** Parse given pipelines array and returns two arrays
 *
 * @param {Array} pipelines  - the pipelines to parse
 *
 * @returns {Object}          - an object containing start-stop and
 *                              non-start-stop pipelines.
 *
 *                          @example
 *                          {
 *                            startStop: [
 *                              { ... },
 *                              { ... }
 *                            ],
 *                            regular: [
 *                              { ... },
 *                              { ... },
 *                              { ... }
 *                            ],
 *                          }
 */
export function extractStartStopPipelines (pipelines = []) {
  const { true: startStop = [], false: regular = [] } = _.groupBy(pipelines, ({ name }) => {
    const isStartStop = name.startsWith('start-stop')
    const nonStartStopName = name.substring('start-stop-'.length)
    const hasMatchingNonStartStop = !_.isEmpty(pipelines.filter((pipeline) => pipeline.name === nonStartStopName))

    // This logic will avoid matching the case of non-start-stop pipelines whose names still begin with
    // `start-stop` (i.e. projects with names beginning with `start-stop`)
    return isStartStop && hasMatchingNonStartStop
  })
  return { startStop, regular }
}

/** Performs check to confirm if image exists
 *
 * @param {String} imagePath  - the path to the image
 * @param {Boolean} external  - **optional** set to true if the image is not within the codebase
 *                              _default_ = false
 * @returns `Boolean` or `resolved Promise` (_value: true or false_)
 */
export function imageExists (imagePath, { external = false } = {}) {
  if (!imagePath) return false
  try {
    if (external) return externalImageExists(imagePath)
    else path.join(__dirname, imagePath)
    return true
  } catch (error) { return false }
}

/** Displays a fullname/username/empty string based on entry params
 *
 * @param {object} [profile] - user profile object. Alternatively, an object containing
 *                             given_name and family_name or username properties

 * @returns {string} - A display name created from given_name and family_name. If these are
                        not provided returns the username. If username is not provided returns
                        an empty string
 */
export function displayName ({ given_name: firstName, family_name: lastName, username = '' } = {}) {
  return _.every([firstName, lastName]) ? `${firstName} ${lastName}` : username
}

/** Returns the SVG of a MDI based on the iconName
 *
 * @param {String} [iconName] - The name of the icon
 * @param {String} [nodeId]   - The id of the svg node
 *
 * @returns {String} The SVG code of the icon
 */
export function mdiToSVG (iconName, nodeId) {
  const id = nodeId + '-svg-icon'
  return icons.svg(iconName, id)
}

/** Typically used for validation checks to see if a form can save
 *
 * @param {Object} [checks]   - key-pairs: key (name of check for self-documenting code), value (a boolean logic check)
 * @returns {Boolean}
 */
export function checksPass (checks = {}) {
  if (!_.isPlainObject(checks)) return console.error(`[checksPass]: was not passed Object`, checks)
  return !_.$isEmpty(checks) && _.every(Object.values(checks))
}

/** Returns true if any of the checks pass
 *
 * @param {Object} [checks]   - key-pairs: key (name of check for self-documenting code), value (a boolean logic check)
 * @returns {Boolean}
 */
export function anyChecksPass (checks = {}) {
  if (!_.isPlainObject(checks)) return console.error(`[anyChecksPass]: was not passed Object`, checks)
  return _.some(Object.values(checks))
}

/** Recursively searches for a property (key) in an object.
 *
 * @param {Object} object     - the object to search
 * @param {String} name       - property name we want to find
 *
 * @returns {String|null}     - A path to the property with a `this.is.a.path` syntax. Returns null if the property does not exist
 */
export function findPropertyPath (object = {}, name = '') {
  if (_.some([object, name], _.$isEmpty)) return null

  for (const property in object) {
    if (property === name) return name
    if (_.isObject(object[property])) {
      const result = findPropertyPath(object[property], name)
      if (result) return `${property}.${result}`
    }
  }
  return null
}

export function getValidOrganization (orgsList = [], routeOrgCanonical = '') {
  return _.find([
    { canonical: routeOrgCanonical },
    JSON.parse(sessionStorage.getItem(LSK.ORGANIZATION)),
    JSON.parse(localStorage.getItem(LSK.ORGANIZATION)),
    orgsList?.[0],
  ], (org) => _.find(orgsList, { canonical: org?.canonical }))
}

/** Returns an Object to populate the icon and its colour
 *
 * @param {String} status       The status returned from the getStatus endpoint
 */
export function getStatusIcon (status) {
  const placeholder = {
    icon: 'sensors',
    color: 'grey',
  }
  return {
    icon: {
      Success: 'fas fa-check-circle',
      Error: 'fas fa-exclamation-circle',
    }[status] || placeholder.icon,
    color: _.some([_.isEmpty(status), status === 'Unknown'])
      ? placeholder.color
      : status.toLowerCase(),
  }
}

/** Returns missing owner object
 *
 * @returns {object}
 */
export function getMissingOwnerObject () {
  return { username: 'no owner', isMissing: true }
}

/** Returns boolean value.
 *
 * @param {object} The owner object containing owner's username
 * @returns {boolean}
 */
export function hasNoOwner (owner = {}) {
  if (_.isString(owner) && !_.isEmpty(owner)) return false
  return owner?.isMissing || _.isEmpty(owner?.username)
}

/** Returns owner's username.
 *
 * @param {object|string} [owner={}] The owner object, or the owner's username itself.
 * @returns {string}
 */
export function getOwnerUsername (owner = {}) {
  if (hasNoOwner(owner)) return ''
  return _.get(owner, 'username', owner) ?? ''
}

/** Transforms the API pagination object to one useful for the application
 *
 * @param {Object.pagination} [original=null] From state
  *                                             - defaults to null if not supplied/original is undefined
  *                                             - { totalRecords: { fetched, available }, pagesFetched, allFetched }
 * @param {Object.pagination} pagination      From API
 *                                              - { size, total, index }
 * @returns {Object}                          { totalRecords: { fetched, available }, pagesFetched, allFetched }
 */
export function formatPagination ({ original = null, pagination }) {
  if (_.get(original, 'allFetched')) return original

  const { size, total, index } = pagination
  const pagesFetched = original
    ? [...original.pagesFetched, index]
    : [index]
  const fetched = total === size
    ? size
    : size + Number(_.get(original, 'recordsFetched', 0))
  const allFetched = total === fetched

  return {
    totalRecords: {
      fetched,
      available: total,
    },
    pagesFetched,
    allFetched,
  }
}

/** Formats number to currency string
 *
 * @param {String} Currency           'EUR'
 *
 * @returns {String}                  '€'
 */
export function getCurrencySymbol (currency = null) {
  if (!currency) return ''
  if (currency === 'CNY') return '¥'
  return new Intl.NumberFormat('en-EN', { style: 'currency', currency }).format(0).slice(0, 1)
}

/** Formats number to currency string
 *
 * @param {Number} amount           143.16
 * @param {Object} options            { currency: 'EUR', signDisplay: 'always' }
 *
 * @returns {String}                  +€143.16'
 */
export function formatAmount (amount, { currency = null, signDisplay = 'auto', decimalPlaces = 2 } = {}) {
  if (!amount && amount !== 0) return '--'
  if (isNaN(amount)) {
    console.warn('[formatAmount] passed a wrong currency shortcode')
    return ''
  }
  const formattedAmount = new Intl.NumberFormat('en-EN', {
    signDisplay,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(amount).replace('-', '‑') // replaces hyphen with non-breaking hyphen to avoid line break in negative numbers

  const currencySymbol = getCurrencySymbol(currency)

  // we want to display costs <0.01 as <€0.01 - same for negative numbers - but zero costs as 0, no decimals
  if (amount > 0 && amount < 0.01) return `<0.01 ${currencySymbol}`
  if (amount < 0 && amount > -0.01) return `>-0.01 ${currencySymbol}`
  if (amount === 0) return `0 ${currencySymbol}`
  return `${formattedAmount} ${currencySymbol}`
}

export const keyMap = {
  config_repositories: 'configRepositories',
  service_catalog_sources: 'catalogRepositories',
  serviceCatalogSources: 'catalogRepositories',
  service_catalogs: 'stacks',
  serviceCatalogs: 'stacks',
  users: 'members',
}

/** We aim to have the same naming everywhere...
 * but until then, these names reflect our UI naming, to make things simpler to understand
 *
 * @param {String} Key      - the key you wish to set on the state
 * @returns String
 * */
export const getKey = (key) => keyMap[key] || key

export const getWidgetComponent = ({ type, widget }) => getKPISetting({ type, widget }).component

/** Converts all values in an Object's unix timestamps from seconds to milliseconds
 *
 * Useful for when FE needs ms but BE provides s.
 */
export function convertTimeValuesToMS (object, { keys = [] } = {}) {
  if (!_.isPlainObject(object)) {
    console.error(`[convertTimeValuesToMS] was not passed an Object.`, object)
    return object
  }

  return _.transform(object, (acc, value, key) => {
    if (_.some([/.+(At|_at)$/.test(key), keys.includes(key)]) && !isNaN(Number(value))) {
      acc[key] = String(value).length === 10 ? Number(value) * 1000 : Number(value)
      return
    }
    acc[key] = value
  })
}

/** Converts all values in an Object's unix timestamps from milliseconds to seconds
 *
 * Useful for when FE needs ms but BE needs s.
 */
export function convertTimeValuesToS (object, { keys = [] } = {}) {
  if (!_.isPlainObject(object)) {
    console.error(`[convertTimeValuesToS] was not passed an Object.`, object)
    return object
  }

  return _.transform(object, (acc, value, key) => {
    if (_.some([/.+(At|_at)$/.test(key), keys.includes(key)]) && !isNaN(Number(value))) {
      acc[key] = String(value).length === 13 ? Math.floor(Number(value) / 1000) : Number(value)
      return
    }
    acc[key] = value
  })
}

/** Generic mutations and actions that can be reused across multiple modules
 *
 * @param {Object} initialState   The initialState in the vuex module
 * @returns {Object}
 * {
 *   actions: {Object},
 *   mutations: {Object}
 * }
 */

export function vuexMixin (initialState) {
  return {
    actions: {
      async BULK_DELETE ({ commit, dispatch, rootGetters: { orgCanonical } }, { keyPath, toDelete, $router, redirectTo = keyPath && getKey(_.last((keyPath).split('.'))), extraParams = [] }) {
        if (_.some([keyPath, toDelete], _.isEmpty)) return console.error(`[BULK_DELETE]: you must define the params keyPath and toDelete`, { keyPath, toDelete })

        const key = _.last(keyPath.split('.'))
        const keyPreferred = getKey(key)
        const isKeyPathOK = keyPreferred === key
        const apiCall = _.get(DELETE, keyPath)
        const isFetchingOrgs = keyPreferred === 'organizations'

        if (isFetchingOrgs) return console.error(`[BULK_DELETE]: you cannot use this action to delete multiple organizations, instead it is used for entities within an org scope`)
        if (!isKeyPathOK) console.warn(`[BULK_DELETE]: please pass the preferred key, as used on the state`, { keyPath, keyPreferred })
        if (!apiCall) return console.error(`[BULK_DELETE]: key passed does not have a matching endpoint listed in utils/config/endpoints.js`, { keyPath })

        commit('CLEAR_ERRORS', keyPreferred)
        const promises = toDelete.map(async ({ canonical, id, name, email }) => {
          const { errors } = await Vue.prototype.$cycloid.ydAPI[apiCall](orgCanonical, ...extraParams, canonical || id) || {}
          if (errors) errors[0].message = `<b><code>${name || canonical || email}</code></b> ${errors[0].message}`
          return errors || name || canonical || email
        })
        const { true: successes, false: errors } = _(await Promise.allSettled(promises)).map('value').groupBy(_.isString).value()

        if (!_.isEmpty(successes)) {
          if ($router) $router.push({ name: redirectTo })
          const deleted = _.$getListFromArray(successes, { boldItems: true })
          const content = i18n.tc('alerts.success.bulkDeleted', successes.length, { deleted })
          dispatch('alerts/SHOW_ALERT', { type: 'success', content }, { root: true })
        }
        if (!_.isEmpty(errors)) commit('SET_ERRORS', { key: keyPreferred, errors: _.flatten(errors) })
      },

      async FETCH_AVAILABLE ({ state, dispatch, commit, rootGetters: { orgCanonical } }, { keyPath, extraParams = [], clearErrors = true, updateFilters = false }) {
        if (_.isEmpty(keyPath)) return console.error(`[FETCH_AVAILABLE]: you must define the keyPath param`, { keyPath })
        if (!_.has(state, 'available')) return console.error(`[FETCH_AVAILABLE]: state must contain the .available property, but it was not found.`)

        const key = _.last(keyPath.split('.'))
        const keyPreferred = getKey(key)
        const isKeyPathOK = keyPreferred === key
        const apiCall = _.get(GET, keyPath)
        const isFetchingOrgs = keyPreferred === 'organizations'

        if (!apiCall && !isFetchingOrgs) return console.error(`[FETCH_AVAILABLE]: GET key was not found in api/endpoints.js`, { keyPath })
        if (!isKeyPathOK) console.warn(`[FETCH_AVAILABLE]: please change to use the preferred key, as used on the state`, { keyPath, keyPreferred })

        if (clearErrors) commit('CLEAR_ERRORS', keyPreferred)
        commit('START_FETCH', keyPreferred)
        if (isFetchingOrgs) await dispatch('FETCH_ORGS', undefined, { root: true })
        else {
          const { data, errors, filters } = await Vue.prototype.$cycloid.ydAPI[apiCall](orgCanonical, ...extraParams) || {}
          if (data) commit('SET_AVAILABLE', { key: keyPreferred, value: data })
          if (updateFilters && filters) commit('SET_FILTERS', { key: keyPreferred, value: filters })
          if (errors) commit('SET_ERRORS', { key: keyPreferred, errors })
        }
        commit('STOP_FETCH', keyPreferred)
      },

      /** This is necessary when BE return a String (username) for .owner
       *  when updating some things. So we need to repopulate the full
       *  owner Object, for use with FE code. To populate name, avatar etc.
       */
      async GET_OWNER_OBJECT ({ dispatch, rootState }, { data: newVal, oldVal }) {
        if (_.has(newVal.owner, 'username')) return newVal

        const username = newVal.owner
        const oldUsername = oldVal.owner?.username ?? oldVal.owner

        if (username === oldUsername && _.has(oldVal.owner, 'username')) {
          return _.merge(newVal, { owner: oldVal.owner })
        }

        await dispatch('organization/FETCH_AVAILABLE', { keyPath: 'members' }, { root: true })

        const fullOwnerObject = _.find(rootState.organization.available.members, ['username', username])
        const backupOwnerObject = { username }

        return _.merge(newVal, { owner: fullOwnerObject || backupOwnerObject })
      },
    },
    mutations: {
      CLEAR_ERRORS (state, key) {
        if (key) Vue.set(state.errors, key, _.cloneDeep(initialState.errors[key]))
        else Vue.set(state, 'errors', _.cloneDeep(initialState.errors))
      },

      RESET_STATE (state, key) {
        if (key) return Vue.set(state, key, _.cloneDeep(initialState)[key])
        for (const field in initialState) Vue.set(state, field, _.cloneDeep(initialState)[field])
      },

      SET_ERRORS (state, { key, errors = [] }) {
        if (!_.isPlainObject(arguments[1])) return console.error('[SET_ERRORS] must be passed an Object', arguments[1])
        if (_.isEmpty(errors)) console.warn(`[SET_ERRORS] passed empty errors, call CLEAR_ERRORS to clear errors instead.`)
        key
          ? Vue.set(state.errors, key, errors)
          : Vue.set(state, 'errors', errors)
      },

      START_FETCH (state, key) {
        Vue.set(state.fetchInProgress, key, true)
      },

      STOP_FETCH (state, key) {
        Vue.set(state.fetchInProgress, key, false)
      },
    },
  }
}

/** Ensure key has lowercased first letter, and modules are clones, not references */
export function parseModules (modules) {
  return _(modules)
    .mapKeys((_module, key) => _.lowerFirst(key))
    .cloneDeep()
}

/**
 * Populates each element with the correct image path
 * If the image is not accessible using the path provided, then it shall show the default image.
 * If the elements are for `vault`, then it populates each image field with the path to the vault logo.
 *
 * @param {Array} elements    - an array of elements
 * @param {String} entityType - either 'resource' or 'dataSource'
 *
 * @returns `Array`
 */
export function populateEachElementImage (elements, entityType) {
  return elements.map((element) => ({ ...element, image: populateElementImage(element, entityType) }))
}

export function hasOwnerChanged (newItem, oldItem) {
  const getOwner = ({ owner }) => hasNoOwner(owner) ? null : _.get(owner, 'username', owner)
  return !_.isEqual(getOwner(newItem), getOwner(oldItem))
}

export {
  throwBetterError,
  typeOf,
}

export default {
  anyChecksPass,
  arrayToObject,
  checksPass,
  constructBreadcrumb,
  convertTimeValuesToMS,
  convertTimeValuesToS,
  decodeJWT,
  displayName,
  externalImageExists,
  extractStartStopPipelines,
  findDuplicates,
  findPropertyPath,
  formatAmount,
  formatPagination,
  getCurrencySymbol,
  getKey,
  getMissingOwnerObject,
  getStatusIcon,
  getValidOrganization,
  getWidgetComponent,
  hasNoOwner,
  hasOwnerChanged,
  imageExists,
  keyMap,
  mdiToSVG,
  parseModules,
  stripInvalidProperties,
  typeOf,
  vuexMixin,
}
