export default {
  en: {
    errors: {
      catalogRepository: {
        refresh: 'There was a problem refreshing <b>{catalogRepoName}</b>:<br/>{errorMessage}',
      },
      inventoryResource: {
        fetch: 'Could not find resource <b>{id}</b>',
      },
      pipeline: {
        configuration: {
          update: 'Pipeline configuration - {errorMessage}',
        },
      },
    },
    warning: {
      pipeline: {
        togglePause: 'There was a problem on the pipeline',
      },
    },
    success: {
      apiKey: {
        created: '@:APIKey added',
        deleted: '<b>{apiKeyName}</b> was successfully deleted',
        reassigned: '@:APIKey <b>{apiKeyName}</b> was reassigned to <b>{owner}</b>',
        updated: '@:APIKey <b>{apiKeyName}</b> updated',
      },
      appearance: {
        created: '<b>{appearanceCanonical}</b> @:appearance created',
        deleted: '<b>{appearanceCanonical}</b> @:appearance deleted',
        updated: '<b>{appearanceCanonical}</b> @:appearance updated',
      },
      auth: {
        verificationEmailSent: 'A verification email has been sent to your inbox',
      },
      buildLeaf: {
        cachesRemoved: 'Caches removed: {cachesRemoved}',
      },
      bulkDeleted: '{deleted} was successfully deleted | {deleted} were successfully deleted',
      catalogRepository: {
        created: '@:CatalogRepository <b>{catalogRepoName}</b> created',
        deleted: '@:CatalogRepository <b>{catalogRepoName}</b> deleted',
        reassigned: '@:CatalogRepository <b>{catalogRepoName}</b> was reassigned to <b>{owner}</b>',
        refreshed: '@:CatalogRepository <b>{catalogRepoName}</b> content was refreshed',
        updated: '@:CatalogRepository <b>{catalogRepoName}</b> updated',
      },
      cloudCostManagement: {
        account: {
          created: `Account created: You will receive an email when the import is complete.`,
          deleted: '<b>{accountCanonical}</b> was successfully deleted',
          linked: `Account created: It may take up to 24 hours to make the data available.`,
          updated: 'Account was successfully updated',
        },
        tagMapping: {
          updated: 'Tag mapping was successfully updated',
        },
      },
      configRepository: {
        created: '@:ConfigRepository <b>{configRepoName}</b> created',
        deleted: '@:ConfigRepository <b>{configRepoName}</b> deleted',
        updated: '@:ConfigRepository <b>{configRepoName}</b> updated',
      },
      credential: {
        created: '@:Credential <b>{credentialName}</b> created',
        deleted: '@:Credential <b>{credentialName}</b> deleted',
        reassigned: '@:Credential <b>{credentialName}</b> reassigned to <b>{owner}</b>',
        updated: '@:Credential <b>{credentialName}</b> updated',
      },
      environment: {
        removeScheduling: 'Scheduling for <b>{envCanonical}</b> was successfully removed',
        started: 'The environment was successfully started',
        stopped: 'The environment was successfully stopped',
        updated: 'The environment configuration was successfully updated',
      },
      events: {
        preferencesUpdated: 'The event configuration was updated successfully!',
      },
      infraPolicy: {
        created: '@:InfraPolicy <b>{infraPolicyName}</b> created',
        deleted: '@:InfraPolicy <b>{infraPolicyName}</b> deleted',
        reassigned: '@:InfraPolicy <b>{infraPolicyName}</b> reassigned to <b>{owner}</b>',
        updated: '@:InfraPolicy <b>{infraPolicyName}</b> updated',
      },
      KPI: {
        created: 'Widget created',
        updated: 'The <b>{KPIName}</b> widget was successfully updated',
        deleted: 'The <b>{KPIName}</b> widget was successfully deleted',
        favorite: {
          created: 'Widget added to favorites.',
          deleted: 'Widget removed from favorites.',
        },
      },
      organization: {
        created: 'The organization <b>{orgName}</b> was successfully created',
        deleted: 'The organization <b>{orgName}</b> was successfully deleted',
        updated: 'The organization <b>{orgName}</b> was successfully updated',
        member: {
          updated: '<b>{username}</b> was successfully updated',
        },
        members: {
          invited: 'One person was invited to join <b>{orgName}</b> | <b>{nb}</b> people were invited to join <b>{orgName}</b>',
          invitesResent: 'One invitation was resent, to join <b>{orgName}</b> | <b>{nb}</b> invitations were resent, to join <b>{orgName}</b>',
          unassigned: '{userNames} was unassigned from <b>{orgName}</b> | {userNames} were unassigned from <b>{orgName}</b>',
        },
        subscription: {
          created: 'Congratulations! Your {planName} plan has started',
        },
      },
      orgLicence: {
        activated: 'Licence successfully activated',
      },
      orgTerraform: {
        created: 'Default Terraform configuration successfully created',
        deleted: 'Default Terraform configuration successfully deleted',
        updated: 'Default Terraform configuration successfully updated',
      },
      pipeline: {
        deleted: 'The <b>{pipelineCanonical}</b> pipeline was successfully deleted',
        toggle: {
          paused: 'Pipeline is paused',
          unpaused: 'Pipeline is running again',
        },
        update: 'Pipeline was updated successfully!',
      },
      profile: {
        updated: 'User profile updated!',
        password: {
          changed: 'Your password has been successfully changed',
        },
        email: {
          verification: {
            sent: 'The verification email has been sent to your inbox',
          },
        },
      },
      project: {
        created: '@:Project <b>{projectName}</b> created',
        deleted: '@:Project <b>{projectName}</b> deleted',
        updated: '@:Project <b>{projectName}</b> updated',
        config: {
          logProviderSaved: 'Logs configuration successfully saved',
          eventProviderSaved: 'Events configuration successfully saved',
          externalBackends: {
            created: 'InfraView configuration successfully created',
            deleted: 'InfraView configuration successfully deleted',
            updated: 'InfraView configuration successfully updated',
          },
          elasticSearch: {
            saved: 'Elastic search logs configuration saved',
          },
          updated: 'The project configuration was updated successfully!',
        },
        env: {
          created: '@:Environment <b>{envCanonical}</b> created',
          deleted: '@:Environment <b>{envCanonical}</b> deleted',
        },
        reassigned: '@:Project <b>{projectName}</b> was reassigned to <b>{owner}</b>',
        favorite: {
          created: '<b>{projectName}</b> project added to favorites.',
          deleted: '<b>{projectName}</b> project removed from favorites.',
        },
      },
      quota: {
        created: '@:Quota successfully created',
        updated: '@:Quota successfully updated',
        deleted: '@:Quota successfully deleted',
      },
      resource: {
        created: '<b>@:Resource successfully created</b>',
        deleted: '<b>@:Resource successfully deleted</b>',
        updated: '<b>@:Resource successfully updated</b>',
      },
      resourcePool: {
        created: '<b>@:ResourcePool successfully created</b>',
        deleted: '<b>@:ResourcePool successfully deleted</b>',
        updated: '<b>@:ResourcePool successfully updated</b>',
      },
      formsVersion: {
        updated: '<b>File version successfully updated</b>',
      },
      stacks: {
        deleted: 'Stack <b>{stackName}</b> was successfully deleted',
        updated: 'Stack <b>{stackName}</b> was successfully updated',
        created: '<b>Stack successfully created!</b><br/>You can now create projects with this stack, but you may also start editing it right away.',
      },
      team: {
        created: '@:Team <b>{teamName}</b> created',
        deleted: '@:Team <b>{teamName}</b> deleted',
        reassigned: '@:Team <b>{teamName}</b> reassigned to <b>{owner}</b>',
        updated: '@:Team <b>{teamName}</b> updated',
        members: {
          assigned: '{userNames} was assigned to <b>{teamName}</b> | {userNames} were assigned to <b>{teamName}</b>',
          unassigned: '{userNames} was removed from <b>{teamName}</b> | {userNames} were removed from <b>{teamName}</b>',
        },
      },
      role: {
        duplicated: 'Role successfully duplicated',
        created: 'Role successfully created',
        deleted: 'Role successfully deleted',
        updated: 'Role successfully updated',
      },
      watchRule: {
        created: 'Watch rule successfully created',
      },
    },
  },
  es: {
    errors: {
      catalogRepository: {
        refresh: 'Hubo un problema al actualizar <b>{catalogRepoName}</b>:<br/>{errorMessage}',
      },
      inventoryResource: {
        fetch: 'No se pudo encontrar el recurso <b>{id}</b>',
      },
      pipeline: {
        configuration: {
          update: '¡La configuración del pipeline - {errorMessage}',
        },
      },
    },
    warning: {
      pipeline: {
        togglePause: 'Hubo un problema en la pipeline',
      },
    },
    success: {
      apiKey: {
        created: 'Se agregó la clave de API',
        deleted: '<b>{apiKeyName}</b> se eliminó correctamente',
        reassigned: 'La clave de API <b>{apiKeyName}</b> se reasignó a <b>{owner}</b>',
        updated: 'Se actualizó la clave de API <b>{apiKeyName}</b>',
      },
      appearance: {
        created: '<b>{appearanceCanonical}</b> @:appearance creada',
        deleted: '<b>{appearanceCanonical}</b> @:appearance eliminada',
        updated: '<b>{appearanceCanonical}</b> @:appearance actualizada',
      },
      auth: {
        verificationEmailSent: 'Se ha enviado un correo electrónico de verificación a su bandeja de entrada.',
      },
      buildLeaf: {
        cachesRemoved: 'Caches eliminados: {cachesRemoved}',
      },
      bulkDeleted: 'El {deleted} se eliminó correctamente | {deleted} se eliminaron correctamente',
      catalogRepository: {
        created: '@:CatalogRepository <b>{catalogRepoName}</b> creado',
        deleted: '@:CatalogRepository <b>{catalogRepoName}</b> eliminado',
        reassigned: '@:CatalogRepository <b>{catalogRepoName}</b> reasignado a <b>{owner}</b>',
        refreshed: '@:CatalogRepository <b>{catalogRepoName}</b> el contenido fue actualizado',
        updated: '@:CatalogRepository <b>{catalogRepoName}</b> actualizado',
      },
      cloudCostManagement: {
        account: {
          created: `Cuenta creada: Recibirás un correo electrónico cuando se complete la importación.`,
          deleted: '<b>{accountCanonical}</b> se eliminó correctamente',
          linked: `Cuenta creada: Puede tardar hasta las 24 horas para que los datos estén disponibles.`,
          updated: 'Cuenta actualizada con éxito',
        },
        tagMapping: {
          updated: 'Mapeo de tags actualizado con éxito',
        },
      },
      configRepository: {
        created: '@:ConfigRepository <b>{configRepoName}</b> creado',
        deleted: '@:ConfigRepository <b>{configRepoName}</b> eliminado',
        updated: '@:ConfigRepository <b>{configRepoName}</b> actualizado',
      },
      credential: {
        created: '@:Credential <b>{credentialName}</b> creado',
        deleted: '@:Credential <b>{credentialName}</b> eliminado',
        reassigned: '@:Credential <b>{credentialName}</b> reasignado a <b>{owner}</b>',
        updated: '@:Credential <b>{credentialName}</b> actualizado',
      },
      environment: {
        removeScheduling: 'La programación para <b>{envCanonical}</b> se eliminó con éxito',
        started: 'El entorno fue iniciado con éxito',
        stopped: 'El entorno fue parado con éxito',
        updated: 'La configuración del entorno se actualizó correctamente',
      },
      events: {
        preferencesUpdated: 'La configuracion de los eventos fué atualizada!',
      },
      infraPolicy: {
        created: '@:InfraPolicy <b>{infraPolicyName}</b> creada',
        deleted: '@:InfraPolicy <b>{infraPolicyName}</b> eliminada',
        reassigned: '@:InfraPolicy <b>{infraPolicyName}</b> reasignado a <b>{owner}</b>',
        updated: '@:InfraPolicy <b>{infraPolicyName}</b> actualizada',
      },
      KPI: {
        created: 'Widget creado',
        updated: 'El widget <b>{KPIName}</b> se actualizó correctamente',
        deleted: 'El widget <b>{KPIName}</b> se eliminó correctamente',
        favorite: {
          created: 'El widget se ha agregado a los favoritos.',
          deleted: 'El widget se ha eliminado de los favoritos.',
        },
      },
      organization: {
        created: 'La organización <b>{orgName}</b> se creó correctamente',
        deleted: 'La organización <b>{orgName}</b> se eliminó correctamente',
        updated: 'La organización <b>{orgName}</b> se actualizó correctamente',
        member: {
          updated: '<b>{username}</b> se actualizó correctamente',
        },
        members: {
          invited: 'Se invitó a una persona a unirse a <b>{orgName}</b> | Se invitó a <b>{nb}</b> personas a unirse a <b>{orgName}</b>',
          invitesResent: 'Una invitación fue reenviada, para unirse a <b>{orgname}</b> | <b> {nb} </b> invitaciones fueron reenviadas, para unirse a <b>{orgname}</b>',
          unassigned: '{userNames} fue removido de <b>{orgName}</b> | {userNames} fueron removidos de <b>{orgName}</b>',
        },
        subscription: {
          created: '¡Enhorabuena! Tu plan de {planName} ha comenzado',
        },
      },
      orgLicence: {
        activated: 'Licencia activada con éxito',
      },
      orgTerraform: {
        created: 'Configuración predeterminada de Terraform creada con éxito',
        deleted: 'La configuración predeterminada de Terraform se eliminó con éxito',
        updated: 'Configuración predeterminada de Terraform actualizada con éxito',
      },
      pipeline: {
        deleted: 'La <b>{pipelineCanonical}</b> pipeline fue eliminada con éxito',
        toggle: {
          paused: 'La pipeline ha sido pausada',
          unpaused: 'La pipeline está en funcionacimiento nuevamente',
        },
        update: '¡La pipeline se ha actualizado con éxito!',
      },
      profile: {
        updated: 'Perfil de usuario actualizado!',
        password: {
          changed: 'Su contraseña ha sido cambiada exitosamente',
        },
        email: {
          verification: {
            sent: 'El correo de verificación ha sido enviado a su bandeja de entrada',
          },
        },
      },
      project: {
        created: '@:Project <b>{projectName}</b> creado',
        deleted: '@:Project <b>{projectName}</b> eliminado',
        updated: '@:Project <b>{projectName}</b> actualizado',
        config: {
          logProviderSaved: 'La configuración de los logs se guardó correctamente',
          eventProviderSaved: 'La configuración de los eventos se guardó correctamente',
          externalBackends: {
            created: 'Configuración de InfraView creada con éxito',
            deleted: `La configuration d'InfraView a été supprimée avec succès`,
            updated: 'La configuración de InfraView se actualizó correctamente',
          },
          elasticSearch: {
            saved: 'Configuración de logs de Elastic Search guardada',
          },
          updated: '¡La configuración del proyecto se ha actualizada!',
        },
        env: {
          created: '@:Environment <b>{envCanonical}</b> creado',
          deleted: '@:Environment <b>{envCanonical}</b> eliminado',
        },
        reassigned: '@:Project <b>{projectName}</b> se reasignó a <b>{owner}</b>',
        favorite: {
          created: 'El proyecto <b>{projectName}</b> se ha agregado a los favoritos.',
          deleted: 'El proyecto <b>{projectName}</b> se ha eliminado de los favoritos.',
        },
      },
      quota: {
        created: '@:Quota creada con éxito',
        updated: '@:Quota actualizada con éxito',
        deleted: '@:Quota eliminada con éxito',
      },
      resource: {
        created: '<b>@:Resource creado con éxito</b>',
        deleted: '<b>El @:resource eliminada con éxito</b>',
        updated: '<b>El @:resource actualizada con éxito</b>',
      },
      resourcePool: {
        created: '<b>El @:resourcePool creada con éxito</b>',
        deleted: '<b>El @:resourcePool eliminada con éxito</b>',
        updated: '<b>El @:resourcePool actualizada con éxito</b>',
      },
      formsVersion: {
        updated: '<b>Versión del file actualizada con éxito</b>',
      },
      stacks: {
        deleted: 'El stack <b>{stackName}</b> ha sido exitosamente eliminado',
        updated: 'El stack <b>{stackName}</b> ha sido exitosamente actualizado',
        created: '<b>¡Stack creado con éxito!</b><br/>Ahora puedes crear proyectos con este stack, pero también puedes comenzar a editarla de inmediato.',
      },
      team: {
        created: '@:Team <b>{teamName}</b> creado',
        deleted: '@:Team <b>{teamName}</b> eliminado',
        reassigned: '@:Team <b>{teamName}</b> reasignado a <b>{owner}</b>',
        updated: '@:Team <b>{teamName}</b> actualizado',
        members: {
          assigned: '{userNames} fue asignado a <b>{teamName}</b> | Se asignaron {userNames} a <b>{teamName}</b>',
          unassigned: '{userNames} se eliminó de <b>{teamName}</b> | {userNames} se eliminaron de <b>{teamName}</b>',
        },
      },
      role: {
        duplicated: 'Rol duplicado con éxito',
        created: 'Rol creado con éxito',
        deleted: 'Rol eliminado con éxito',
        updated: 'Rol actualizado con éxito',
      },
      watchRule: {
        created: 'Regla de monitoreo creada con éxito',
      },
    },
  },
  fr: {
    errors: {
      catalogRepository: {
        refresh: `Un problème est survenu lors de l'actualisation de <b>{catalogRepoName}</b>:<br/>{errorMessage}`,
      },
      inventoryResource: {
        fetch: 'Impossible de trouver la ressource <b>{id}</b>',
      },
      pipeline: {
        configuration: {
          update: 'La configuration du pipeline - {errorMessage}',
        },
      },
    },
    warning: {
      pipeline: {
        togglePause: 'Il y a eu un problème avec la pipeline',
      },
    },
    success: {
      apiKey: {
        created: 'Clé API ajoutée',
        deleted: '<b>{apiKeyName}</b> a été supprimée avec succès',
        reassigned: 'Clé API <b>{apiKeyName}</b> a été réaffecté à <b>{owner}</b>',
        updated: 'Clé API <b>{apiKeyName}</b> mise à jour',
      },
      appearance: {
        created: '<b>{appearanceCanonical}</b> @:appearance créé',
        deleted: '<b>{appearanceCanonical}</b> @:appearance supprimé',
        updated: '<b>{appearanceCanonical}</b> @:appearance modifié',
      },
      auth: {
        verificationEmailSent: 'Un email de vérification a été envoyé dans votre boîte de réception',
      },
      buildLeaf: {
        cachesRemoved: 'Caches supprimés: {cachesRemoved}',
      },
      bulkDeleted: '{deleted} a bien été supprimée | Les {deleted} ont bien été supprimées',
      catalogRepository: {
        created: '@:CatalogRepository <b>{catalogRepoName}</b> créé',
        deleted: '@:CatalogRepository <b>{catalogRepoName}</b> supprimée',
        reassigned: '@:CatalogRepository <b>{catalogRepoName}</b> réaffecté à <b>{owner}</b>',
        refreshed: '@:CatalogRepository <b>{catalogRepoName}</b> le contenu a été actualisé',
        updated: '@:CatalogRepository <b>{catalogRepoName}</b> mise à jour',
      },
      cloudCostManagement: {
        account: {
          created: `Compte créé: Vous recevrez un e-mail lorsque l'importation sera terminée.`,
          deleted: '<b>{accountCanonical}</b> a été supprimée avec succès',
          linked: `Compte créé: La mise à disposition des données peut prendre jusqu'à 24 heures.`,
          updated: 'Le compte a été mis à jour avec succès',
        },
        tagMapping: {
          updated: 'Les associations de tags ont été mises à jour avec succès',
        },
      },
      configRepository: {
        created: '@:ConfigRepository <b>{configRepoName}</b> créée',
        deleted: '@:ConfigRepository <b>{configRepoName}</b> supprimée',
        updated: '@:ConfigRepository <b>{configRepoName}</b> modifiée',
      },
      credential: {
        created: `@:Credential <b>{credentialName}</b> créé`,
        deleted: `@:Credential <b>{credentialName}</b> supprimée`,
        reassigned: `@:Credential <b>{credentialName}</b> réaffecté à <b>{owner}</b>`,
        updated: `@:Credential <b>{credentialName}</b> mise à jour`,
      },
      environment: {
        removeScheduling: 'La planification pour <b>{envCanonical}</b> a été supprimée avec succès',
        started: `L'environnement a été démarré avec succès`,
        stopped: `L'environnement a été éteint avec succès`,
        updated: `La configuration de l'environnement a été mise à jour avec succès`,
      },
      events: {
        preferencesUpdated: 'La configuration des evenements a été mise à jour !',
      },
      infraPolicy: {
        created: '@:InfraPolicy <b>{infraPolicyName}</b> créé',
        deleted: `@:InfraPolicy <b>{infraPolicyName}</b> supprimée`,
        reassigned: '@:InfraPolicy <b>{infraPolicyName}</b> réaffecté à <b>{owner}</b>',
        updated: '@:InfraPolicy <b>{infraPolicyName}</b> actualisée',
      },
      KPI: {
        created: 'Widget créé',
        updated: 'Widget <b>{KPIName}</b> a bien été mis à jour',
        deleted: 'Le widget <b>{KPIName}</b> a bien été supprimé',
        favorite: {
          created: 'Le widget a été ajouté aux favoris',
          deleted: 'Le widget a été retiré des favoris',
        },
      },
      organization: {
        created: `L'organisation <b>{orgName}</b> a été créée avec succès`,
        deleted: `L'organisation <b>{orgName}</b> a bien été supprimée`,
        updated: `L'organisation <b>{orgName}</b> a été mise à jour avec succès`,
        member: {
          updated: '<b>{username}</b> a été mis à jour avec succès',
        },
        members: {
          invited: 'Une personne a été invitée à rejoindre {orgName} | <b>{nb}</b> personnes ont été invitées à rejoindre <b>{orgName}</b>',
          invitesResent: 'Une invitation a été renvoyée, pour rejoindre <b>{orgname}</b> | <b> {nb} </b> invitations ont été renvoyées, pour rejoindre <b>{orgname}</b>',
          unassigned: '{userNames} a été retiré de <b>{orgName}</b> | {userNames} ont été retirés de <b>{orgName}</b>',
        },
        subscription: {
          created: 'Félicitations! Votre plan {planName} a commencé',
        },
      },
      orgLicence: {
        activated: 'Licence activée avec succès',
      },
      orgTerraform: {
        created: 'La configuration Terraform par défaut a été créée avec succès',
        deleted: 'La configuration Terraform par défaut a été supprimée avec succès',
        updated: 'La configuration Terraform par défaut a été mise à jour avec succès',
      },
      pipeline: {
        deleted: 'La pipeline <b>{pipelineCanonical}</b> a été supprimée avec succès',
        toggle: {
          paused: 'La pipeline est en pause',
          unpaused: 'La pipeline a été réactivée',
        },
        update: 'Pipeline ont été mises à jour!',
      },
      profile: {
        updated: 'Le profil utilisateur a été mis à jour !',
        password: {
          changed: 'Votre mot de passe a été changé avec succès',
        },
        email: {
          verification: {
            sent: `L'email de vérification a été envoyé dans votre boîte de réception`,
          },
        },
      },
      project: {
        created: '@:Project <b>{projectName}</b> créé',
        deleted: '@:Project <b>{projectName}</b> supprimé',
        updated: '@:Project <b>{projectName}</b> mis à jour',
        config: {
          updated: 'La configuration du projet a été mise à jour !',
          logProviderSaved: 'La configuration des logs a été enregistrée avec succès',
          eventProviderSaved: 'La configuration des événements a été enregistrée avec succès',
          externalBackends: {
            created: 'La configuration de InfraView a été créée avec succès',
            deleted: 'La configuración de InfraView se eliminó correctamente',
            updated: `La configuration d'InfraView a été mise à jour avec succès`,
          },
          elasticSearch: {
            saved: 'Configuration des logs Elastic Search enregistrée',
          },
        },
        env: {
          created: '@:Environment <b>{envCanonical}</b> créé',
          deleted: `@:Environment <b>{envCanonical}</b> supprimé`,
        },
        reassigned: '@:Project <b>{projectName}</b> a été réaffecté à <b>{owner}</b>',
        favorite: {
          created: 'Le projet <b>{projectName}</b> a été ajouté aux favoris',
          deleted: 'Le projet <b>{projectName}</b> a été retiré des favoris',
        },
      },
      quota: {
        created: '@:Quota créé avec succès',
        updated: '@:Quota mis à jour avec succès',
        deleted: '@:Quota supprimé avec succès',
      },
      resource: {
        created: '<b>@:Resource créé avec succès</b>',
        deleted: '<b>@:Resource supprimée avec succès</b>',
        updated: '<b>@:Resource mise à jour avec succès</b>',
      },
      resourcePool: {
        created: '<b>Le @:resourcePool créé avec succès</b>',
        deleted: '<b>Le @:resourcePool supprimé avec succès</b>',
        updated: '<b>Le @:resourcePool mis à jour avec succès</b>',
      },
      formsVersion: {
        updated: '<b>Version du fichier mise à jour avec succès</b>',
      },
      stacks: {
        deleted: 'Le Stack <b>{stackName}</b> a été supprimée avec succès',
        updated: 'Le Stack <b>{stackName}</b> a été mis à jour avec succès',
        created: '<b>Stack créé avec succès !</b><br/>Vous pouvez maintenant créer des projets avec cette stack, mais vous pouvez également commencer à la modifier immédiatement.',
      },
      team: {
        created: '@:Team <b>{teamName}</b> est créée',
        deleted: `@:Team <b>{teamName}</b> supprimée`,
        reassigned: '@:Team <b>{teamName}</b> réaffecté à <b>{owner}</b>',
        updated: '@:Team <b>{teamName}</b> mise à jour',
        members: {
          assigned: '{userNames} a été attribué à <b>{teamName}</b> | {userNames} ont été attribués à <b>{teamName}</b>',
          unassigned: '{userNames} a été supprimé de <b>{teamName}</b> | {userNames} ont été supprimés de <b>{teamName}</b>',
        },
      },
      role: {
        duplicated: 'Rôle dupliqué avec succès',
        created: 'Rôle créé avec succès',
        deleted: 'Rôle supprimé avec succès',
        updated: 'Rôle mis à jour avec succès',
      },
      watchRule: {
        created: 'Règle de notification créée avec succès',
      },
    },
  },
}
