import store from '@/store'
import { objectToListOfPairs, convertOwnerToUsername } from '@/utils/api/client'
import alphaData from './alpha-data' // eslint-disable-line no-unused-vars

/**
  * All API operations should be named the same as outlined within the BE's swagger spec.
  * @see https://docs.staging.cycloid.io/api/index.html
  * @see https://github.com/cycloidio/youdeploy-http-api/blob/develop/swagger.yml
 */

// API Error codes present in the error details payload contained in the error payload.
// @see Cycloid API conventions in the Cycloid wiki and YD API spec.
export const ERROR_CODE_ALREADY_EXISTS = 'already_exists'
export const ERROR_CODE_INVALID = 'invalid'
export const ERROR_CODE_INVALID_FIELD = 'invalid_field'
export const ERROR_CODE_UNAUTHENTICATED = 'unauthenticated'
export const ERROR_CODE_REFRESH_TOKEN = 'UnauthorizedRefreshToken'
export const DELETE = 'DELETE'
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'

/** ALPHA endpoints: usually return mock data
  *
  * ! Once the BE endpoint is available:
  * - rename and move each ALPHA endpoint
  * - rework the syntax to match already implemented endpoints
  * - correct the doc link
  * - delete the associated mock data files
  */
const ALPHA = {} // eslint-disable-line no-unused-vars

/** https://docs.staging.cycloid.io/api/index.html#operation/abortBuild */
const abortBuild = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobName, buildId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobName}/builds/${buildId}/abort`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/activateLicence */
const activateLicence = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/licence`,
    { body },
    statusFromHandlers(handlers, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/assignMemberToTeam */
const assignMemberToTeam = (...params) => async (orgCanonical, teamCanonical, username) => {
  const [authRequest, ...handlers] = params
  const body = { username }
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/teams/${teamCanonical}/members`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/canDo */
const canDo = (...params) => async (orgCanonical, { key: action }, canonical) => {
  const [authRequest, ...handlers] = params
  const body = {
    action,
    entity_canonicals: _.filter([canonical]),
  }

  if (store.state.dev.showDevThings) store.commit('dev/ADD_PERMISSION_FETCHED', { action, canonical })
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/can_do`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/checkResource */
const checkResource = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName) => {
  const [authRequest, ...handlers] = params
  const body = {}
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/check`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/clearTaskCache */
const clearTaskCache = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical, jobCanonical, taskCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/jobs/${jobCanonical}/tasks/${taskCanonical}/cache`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createAppearance */
const createAppearance = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/appearances`,
    { body },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/costEstimateForms */
const costEstimateForms = (...params) => async (orgCanonical, projectCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/forms/estimate`,
    { body },
    statusFromHandlers(handlers, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createCloudCostManagementAccount */
const createCloudCostManagementAccount = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/cloud_cost_management/accounts`,
    { body },
    statusFromHandlers(handlers, 404, 409, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createCloudCostManagementAccountChild */
const createCloudCostManagementAccountChild = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/cloud_cost_management/child-accounts`,
    { body },
    statusFromHandlers(handlers, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteCloudCostManagementAccount */
const deleteCloudCostManagementAccount = (...params) => async (orgCanonical, accountCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/cloud_cost_management/accounts/${accountCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCloudCostManagementAccount */
const getCloudCostManagementAccount = (...params) => async (orgCanonical, accountCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/cloud_cost_management/accounts/${accountCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCloudCostManagementAccounts */
const getCloudCostManagementAccounts = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/cloud_cost_management/accounts`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 403, 404, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCloudCostManagementAccountsHasParent */
const getCloudCostManagementAccountsHasParent = (...params) => async (orgCanonical, queryString) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/cloud_cost_management/accounts/has_parent?${queryString}`,
    null,
    statusFromHandlers(handlers, 403, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCloudCostManagementTagMapping */
const getCloudCostManagementTagMapping = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/cloud_cost_management/tag_mapping`,
    null,
    statusFromHandlers(handlers, 403, 404, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/putCloudCostManagementTagMapping */
const updateCloudCostManagementTagMapping = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/cloud_cost_management/tag_mapping`,
    { body },
    statusFromHandlers(handlers, 411, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCloudCostManagementProvider */
const getCloudCostManagementProvider = (...params) => async (orgCanonical, providerCanonical, queryString) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/cloud_cost_management/${providerCanonical}${queryString}`,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCloudCostManagementDashboard */
const getCloudCostManagementDashboard = (...params) => async (orgCanonical, queryString) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/cloud_cost_management${queryString}`,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateCloudCostManagementAccount */
const updateCloudCostManagementAccount = (...params) => async (orgCanonical, accountCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/cloud_cost_management/accounts/${accountCanonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getInventoryResources */
const getInventoryResources = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/inventory`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listQuotas */
const listQuotas = (...params) => async (orgCanonical, query) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/quotas`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createQuota */
const createQuota = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/quotas`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getQuota */
const getQuota = (...params) => async (orgCanonical, quotaId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/quotas/${quotaId}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateQuota */
const updateQuota = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/quotas/${body.id}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteQuota */
const deleteQuota = (...params) => async (orgCanonical, quotaId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/quotas/${quotaId}`,
    statusFromHandlers(handlers, 403, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/??? */
const getQuotaUsage = (...params) => async (orgCanonical, quotaId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/quotas/${quotaId}/consumptions`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listAppearances */
const listAppearances = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/appearances`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listResourcesByLabel */
const listResourcesByLabel = (...params) => async (orgCanonical, provider, label) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    resources_provider: provider,
    'resources_label[eq]': label,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/inventory`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listResourceTypes */
const listResourceTypes = (...params) => async (orgCanonical, provider) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ resources_provider: provider }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/inventory/resource_types`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listResourceLabels */
const listResourceLabels = (...params) => async (orgCanonical, provider) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ resources_provider: provider }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/inventory/resource_labels`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createResource */
const createResource = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/inventory/resources`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteResource */
const deleteResource = (...params) => async (orgCanonical, resourceId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/inventory/resources/${resourceId}`,
    null,
    statusFromHandlers(handlers, 403, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getResource */
const getResource = (...params) => async (orgCanonical, resourceId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/inventory/resources/${resourceId}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateResource */
const updateResource = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/inventory/resources/${body.id}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createResourcePool */
const createResourcePool = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/resource_pools`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteResourcePool */
const deleteResourcePool = (...params) => async (orgCanonical, resourcePoolCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/resource_pools/${resourcePoolCanonical}`,
    statusFromHandlers(handlers, 403, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getResourcePool */
const getResourcePool = (...params) => async (orgCanonical, resourcePoolCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/resource_pools/${resourcePoolCanonical}`,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listResourcePools */
const listResourcePools = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/resource_pools`,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateResourcePool */
const updateResourcePool = (...params) => async (orgCanonical, resourcePoolCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/resource_pools/${resourcePoolCanonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getEnvQuotaRequirement */
const getEnvQuotaRequirement = (...params) => async (orgCanonical, projectCanonical, { stackFormsInput }) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/forms/quotas`,
    { body: stackFormsInput },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createAPIKey */
const createAPIKey = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body, { isCreation: true })
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/api_keys`,
    { body },
    statusFromHandlers(handlers, 422, 404, 500, 409),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createBuild */
const createBuild = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobName) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobName}/builds`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createConfigRepository */
const createConfigRepository = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/config_repositories`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createConfigRepositoryConfig */
const createConfigRepositoryConfig = (...params) => async (orgCanonical, configRepositoryCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/config_repositories/${configRepositoryCanonical}/config`,
    { body },
    statusFromHandlers(handlers, 403, 404, 411, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createCredential */
const createCredential = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body, { isCreation: true })
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/credentials`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createExternalBackend */
const createExternalBackend = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/external_backends`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createFormsConfig */
const createFormsConfig = (...params) => async (orgCanonical, projectCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/forms/config`,
    { body },
    statusFromHandlers(handlers, 403, 404, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/validateFormsFile */
const validateFormsFile = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/forms/validate`,
    { body },
    statusFromHandlers(handlers, 403, 404, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createInfraImport */
const createInfraImport = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/infra_imports`,
    { body },
    statusFromHandlers(handlers, 409, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createInfraPolicy */
const createInfraPolicy = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body, { isCreation: true })
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/infra_policies`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createKpi */
const createKPI = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/kpis`,
    { body },
    statusFromHandlers(handlers, 422, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createKPIFavorite */
const createKPIFavorite = (...params) => async (orgCanonical, KPICanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/kpis/${KPICanonical}/favorites`,
    null,
    statusFromHandlers(handlers, 403, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createOAuthUser */
const createOAuthUser = (...params) => async (socialType, body) => {
  const [publicRequest] = params
  const res = await publicRequest(
    POST,
    `user/${socialType}/oauth`,
    { body },
    [401, 422],
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createOrg */
const createOrg = (...params) => async (body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createOrgChild */
const createOrgChild = (...params) => async (body, parentCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${parentCanonical}/children`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createOrgRole */
const createOrgRole = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/roles`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createPipeline */
const createPipeline = (...params) => async (orgCanonical, projectCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createProject */
const createProject = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body, { isCreation: true })
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/#tag/Organization-projects/operation/createEnvironment */
const createProjectEnvironment = (...params) => async (orgCanonical, projectCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/environments`,
    { body },
    statusFromHandlers(handlers, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createProjectFavorite */
const createProjectFavorite = (...params) => async (orgCanonical, projectCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/favorites`,
    null,
    statusFromHandlers(handlers, 403, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createServiceCatalog */
const createServiceCatalog = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/service_catalogs`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createServiceCatalogFromTemplate */
const createServiceCatalogFromTemplate = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/service_catalogs/${body.template_ref}/template`,
    { body: _.omit(body, 'template_ref') },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createServiceCatalogSource */
const createServiceCatalogSource = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body, { isCreation: true })
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/service_catalog_sources`,
    { body },
    statusFromHandlers(handlers, 409, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createSubscription */
const createSubscription = (...params) => async (orgCanonical, planCanonical) => {
  const [authRequest, ...handlers] = params
  const body = { plan_canonical: planCanonical }
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/subscriptions`,
    { body },
    statusFromHandlers(handlers, 404, 411, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createTeam */
const createTeam = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body, { isCreation: true })
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/teams`,
    { body },
    statusFromHandlers(handlers, 422),
  )

  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteAPIKey */
const deleteAPIKey = (...params) => async (orgCanonical, apiKeyCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/api_keys/${apiKeyCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteAppearance */
const deleteAppearance = (...params) => async (orgCanonical, appearanceCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/appearances/${appearanceCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteConfigRepository */
const deleteConfigRepository = (...params) => async (orgCanonical, configRepositoryCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/config_repositories/${configRepositoryCanonical}`,
    null,
    statusFromHandlers(handlers, 409),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteCredential */
const deleteCredential = (...params) => async (orgCanonical, credentialCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/credentials/${credentialCanonical}`,
    null,
    statusFromHandlers(handlers, 409),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteExternalBackend */
const deleteExternalBackend = (...params) => async (orgCanonical, externalBackendId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/external_backends/${externalBackendId}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteInfraPolicy */
const deleteInfraPolicy = (...params) => async (orgCanonical, infraPolicyCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/infra_policies/${infraPolicyCanonical}`,
    null,
    statusFromHandlers(handlers, 409),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteInvitation */
const deleteInvitation = (...params) => async (orgCanonical, inviteId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/members/${inviteId}`,
    null,
    statusFromHandlers(handlers, 403, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteKpi */
const deleteKPI = (...params) => async (orgCanonical, KPICanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/kpis/${KPICanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteKPIFavorite */
const deleteKPIFavorite = (...params) => async (orgCanonical, KPICanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/kpis/${KPICanonical}/favorites`,
    null,
    statusFromHandlers(handlers, 403, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteOrg */
const deleteOrg = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteOrgRole */
const deleteOrgRole = (...params) => async (orgCanonical, roleCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/roles/${roleCanonical}`,
    null,
    statusFromHandlers(handlers, 404, 409, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deletePipeline */
const deletePipeline = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteProject */
const deleteProject = (...params) => async (orgCanonical, projectCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/projects/${projectCanonical}`,
    null,
    statusFromHandlers(handlers, 403),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteProjectEnvironment */
const deleteProjectEnvironment = (...params) => async (orgCanonical, projectCanonical, environmentCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/environments/${environmentCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteProjectFavorite */
const deleteProjectFavorite = (...params) => async (orgCanonical, projectCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/favorites`,
    null,
    statusFromHandlers(handlers, 403, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteServiceCatalog */
const deleteServiceCatalog = (...params) => async (orgCanonical, stackRef) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}`,
    null,
    statusFromHandlers(handlers, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteServiceCatalogSource */
const deleteServiceCatalogSource = (...params) => async (orgCanonical, serviceCatalogSourceCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/service_catalog_sources/${serviceCatalogSourceCanonical}`,
    null,
    statusFromHandlers(handlers, 409),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteTeam */
const deleteTeam = (...params) => async (orgCanonical, teamCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/teams/${teamCanonical}`,
    null,
    statusFromHandlers(handlers, 403, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/deleteUserAccount */
const deleteUserAccount = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/user`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/diffPipeline */
const diffPipeline = (...params) => async (orgCanonical, pipelineCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/pipelines/${pipelineCanonical}/diff`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/disableResourceVersion */
const disableResourceVersion = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName, versionId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/versions/${versionId}/disable`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/emailVerification */
const emailVerification = (...params) => async (verificationToken) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/user/email/verification/${verificationToken}`,
    null,
    statusFromHandlers(handlers, 404, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/emailVerificationResend */
const emailVerificationResend = (...params) => async (body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/user/email/verification`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/enableResourceVersion */
const enableResourceVersion = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName, versionId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/versions/${versionId}/enable`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getActiveAppearance */
const getActiveAppearance = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/appearance/${orgCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getAPIKey */
const getAPIKey = (...params) => async (orgCanonical, apiKeyCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/api_keys/${apiKeyCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getAPIKeys */
const getAPIKeys = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/api_keys`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getConfig */ // ! different from BE name: for clarity
const getAppConfig = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    '/config',
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getAppearance */
const getAppearance = (...params) => async (orgCanonical, appearanceCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/appearances/${appearanceCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getAppVersion */
const getAppVersion = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/version`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getBuild */
const getBuild = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobName, buildId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobName}/builds/${buildId}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getBuildPlan */
const getBuildPlan = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical, jobCanonical, buildId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/jobs/${jobCanonical}/builds/${buildId}/plan`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getBuildPreparation */
const getBuildPreparation = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobName, buildId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobName}/builds/${buildId}/preparation`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getBuildResources */
const getBuildResources = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical, jobCanonical, buildId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/jobs/${jobCanonical}/builds/${buildId}/resources`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getBuilds */
const getBuilds = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobName) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobName}/builds`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getBuildsWithVersionAsInput */
const getBuildsWithVersionAsInput = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName, versionId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/versions/${versionId}/input_to`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getBuildsWithVersionAsOutput */
const getBuildsWithVersionAsOutput = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName, versionId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/versions/${versionId}/output_of`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCloudProviders */
const getCloudProviders = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/cloud_providers`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getConfigRepositories */
const getConfigRepositories = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/config_repositories`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getConfigRepository */
const getConfigRepository = (...params) => async (orgCanonical, configRepositoryCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/config_repositories/${configRepositoryCanonical}`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCountries */
const getCountries = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/countries`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCredential */
const getCredential = (...params) => async (orgCanonical, credentialCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/credentials/${credentialCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listCredentials */
const listCredentials = (...params) => async (orgCanonical, type, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    credential_type: type,
    ...pagination,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/credentials`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getCredentialOptions */
const getCredentialOptions = (...params) => async (orgCanonical, credentialCanonical, service) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/credentials/${credentialCanonical}/options?service=${service}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** A custom call, created in order to fetch cycloid creds only:
 * - vault
 * - cycloid-worker-keys
 * */
const getCycloidCredential = (...params) => async (orgCanonical, credentialCanonical) => {
  const [authRequest, ...handlers] = params
  if (!['vault', 'cycloid-worker-keys'].includes(credentialCanonical)) return console.error('This endpoint is for Cycloid creds only. Please use "getCredential" instead')
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/credentials/${credentialCanonical}`,
    null,
    statusFromHandlers(handlers, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getDefaultAppearance */
const getDefaultAppearance = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    '/appearance',
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getEnvironments */
const getEnvironments = (...params) => async (orgCanonical, pagination = {}) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/environments`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getExternalBackend */
const getExternalBackend = (...params) => async (orgCanonical, externalBackendId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/external_backends/${externalBackendId}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getExternalBackends */
const getExternalBackends = (...params) => async (orgCanonical, query) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/external_backends`,
    { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getFormsValuesRef */
const getFormsValuesRef = (...params) => async (orgCanonical, url) => {
  const [authRequest, ...handlers] = params
  const body = { url }
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/forms/values_ref`,
    { body },
    statusFromHandlers(handlers, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getInfraImportResource */
const getInfraImportResource = (...params) => async ({ orgCanonical, providerId, resourceCanonical, abbreviation, tags, body: { credential_canonical: cred, ...configuration } }) => {
  const [authRequest, ...handlers] = params
  const body = {
    configuration: { ...configuration, type: `CloudProvider${abbreviation}Configuration` },
    credential_canonical: cred,
    tags,
  }
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/code_generation/infra_import/providers/${providerId}/resources/${resourceCanonical}`,
    { body },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getInfraImportResources */
const getInfraImportResources = (...params) => async ({ orgCanonical, providerId, abbreviation, body: { credential_canonical: cred, ...configuration } }) => {
  const [authRequest, ...handlers] = params
  const body = {
    configuration: { ...configuration, type: `CloudProvider${abbreviation}Configuration` },
    credential_canonical: cred,
  }
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/code_generation/infra_import/providers/${providerId}/resources`,
    { body },
    statusFromHandlers(handlers, 409, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getInfraPolicies */
const getInfraPolicies = (...params) => async (orgCanonical, query) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/infra_policies`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getInfraPolicy */
const getInfraPolicy = (...params) => async (orgCanonical, infraPolicyCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/infra_policies/${infraPolicyCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getInvitations */
const getInvitations = (...params) => async (orgCanonical, query) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/members`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getJob */
const getJob = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical, jobCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/jobs/${jobCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getJobs */
const getJobs = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/jobs`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getKpi */
const getKPI = (...params) => async (orgCanonical, KPICanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/kpis/${KPICanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getKpis */
const getKPIs = (...params) => async (orgCanonical, projectCanonical, envCanonical, begin, end, favorite, operator = 'eq') => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    project_canonical: projectCanonical,
    environment: envCanonical,
    begin,
    end,
    [`favorite[${operator}]`]: favorite,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/kpis`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getLicence */
const getLicence = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/licence`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOAuthUser */
const getOAuthUser = (...params) => async (socialType, query) => {
  const [publicRequest] = params
  const res = await publicRequest(
    GET,
    `user/${socialType}/oauth`,
    { query },
    [401, 422],
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrg */
const getOrg = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgAncestors */
const getOrgAncestors = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/ancestors`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgChildren */
const getOrgChildren = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/children`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgCost */
const getOrgCost = (...params) => async (orgCanonical, credentialCanonical, granularity, metrics, begin, end, services, groupBy, tag) => {
  const [authRequest, ...handlers] = params
  const query = objectToListOfPairs(_.pickBy({
    credential_canonical: credentialCanonical,
    group_by: groupBy,
    granularity,
    metrics,
    begin,
    end,
    services,
    tag,
  }, (item) => !_.$isEmpty(item)))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/billing/aws`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgCostServices */
const getOrgCostServices = (...params) => async (orgCanonical, credentialCanonical) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    credential_canonical: credentialCanonical,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/billing/aws/services`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgEvents */
const getOrgEvents = (...params) => async (orgCanonical, query) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/events`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgEventsTags */
const getOrgEventsTags = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/events/tags`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgMember */
const getOrgMember = (...params) => async (orgCanonical, username) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/members/${username}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgMembers */
const getOrgMembers = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/members`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgRole */
const getOrgRole = (...params) => async (orgCanonical, roleCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET, `/organizations/${orgCanonical}/roles/${roleCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgRoles */
const getOrgRoles = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/roles`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgRunningInfraAWS */
const getOrgRunningInfraAWS = (...params) => async (orgCanonical, credentialCanonical, projectCanonical, resource, tag) => {
  const [authRequest, ...handlers] = params
  const query = objectToListOfPairs(_.pickBy({
    credential_canonical: credentialCanonical,
    project: projectCanonical,
    resource,
    tag,
  }, (item) => !_.$isEmpty(item)))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/infrastructures/aws`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgRunningInfraAWSInstances */
const getOrgRunningInfraAWSInstances = (...params) => async (orgCanonical, credentialCanonical, projectCanonical, tag) => {
  const [authRequest, ...handlers] = params
  const query = objectToListOfPairs(_.pickBy({
    credential_canonical: credentialCanonical,
    project: projectCanonical,
    tag,
  }, (item) => !_.$isEmpty(item)))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/infrastructures/aws/instances`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgSummary */
const getOrgSummary = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/summary`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getOrgs */
const getOrgs = (...params) => async (pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    '/organizations',
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getPendingInvitation */
const getPendingInvitation = (...params) => async (verificationToken) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/members/verify/${verificationToken}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getPipeline */
const getPipeline = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getPipelineConfig */
const getPipelineConfig = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/config`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getResource */ // ! different from BE name: for clarity
const getPipelineResource = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getPipelineResources */
const getPipelineResources = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/resources`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getPipelineVariables */
const getPipelineVariables = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}/variables`,
    null,
    statusFromHandlers(handlers, 404, 409, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getPipelines */
const getPipelines = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/pipelines`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getPolicies */
const getPolicies = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/policies`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProjEnvLogEntries */
const getProjEnvLogEntries = (...params) => async ({ orgCanonical, projectCanonical, envCanonical, source, begin, end, query: theQuery }) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    query: theQuery,
    begin,
    end,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/environments/${envCanonical}/logs/sources/${source}`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProjEnvLogSources */
const getProjEnvLogSources = (...params) => async (orgCanonical, projectCanonical, envCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/environments/${envCanonical}/logs/sources`,
    null,
    statusFromHandlers(handlers, 404),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProjInfrastructure */
const getProjInfrastructure = (...params) => async (orgCanonical, projectCanonical, envCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/environments/${envCanonical}/infrastructure`,
    null,
    statusFromHandlers(handlers, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProject */
const getProject = (...params) => async (orgCanonical, projectCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProjectConfig */ // ! different from BE name: for clarity
const getProjectEnvironmentConfig = (...params) => async (orgCanonical, projectCanonical, envCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/environment/${envCanonical}/config`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProjectImport */
const getProjectImport = (...params) => async (orgCanonical, projectCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/import`,
    null,
    statusFromHandlers(handlers, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/syncedPipeline */
const syncedPipeline = (...params) => async (orgCanonical, pipelineCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/pipelines/${pipelineCanonical}/synced`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProjectPipelines */
const getProjectPipelines = (...params) => async (orgCanonical, projectCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getProjects */
const getProjects = (...params) => async (orgCanonical, { pagination, configRepositoryId, catalogRepositoryId, credentialId, favorite, filters, operator = 'eq' } = {}) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    pagination,
    [`project_config_repository_id[${operator}]`]: configRepositoryId,
    [`service_catalog_source_id[${operator}]`]: catalogRepositoryId,
    [`credential_id[${operator}]`]: credentialId,
    [`favorite[${operator}]`]: favorite,
    ...filters,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getRepoBranches */
const getRepoBranches = (...params) => async (orgCanonical, credentialCanonical, gitUrl) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    credential_canonical: credentialCanonical,
    git_url: gitUrl,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/branches`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 404, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getResourceVersions */
const getResourceVersions = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/versions`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalog */
const getServiceCatalog = (...params) => async (stackRef, orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalogConfig */
const getServiceCatalogConfig = (...params) => async (stackRef, orgCanonical, useCase = null, projectCanonical = null, envCanonical = null) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    use_case: useCase,
    project_canonical: projectCanonical,
    environment_canonical: envCanonical,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/config`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalogImport */
const getServiceCatalogImport = (...params) => async (orgCanonical, stackRef) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/import`,
    null,
    statusFromHandlers(handlers, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalogSource */
const getServiceCatalogSource = (...params) => async (orgCanonical, serviceCatalogSourceCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalog_sources/${serviceCatalogSourceCanonical}`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalogSources */
const getServiceCatalogSources = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalog_sources`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalogTerraform */
const getServiceCatalogTerraform = (...params) => async ({ orgCanonical, stackRef }) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/default/terraform`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalogTerraformDiagram */
const getServiceCatalogTerraformDiagram = (...params) => async ({ orgCanonical, stackRef }) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/terraform/diagram`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getServiceCatalogs */
const getServiceCatalogs = (...params) => async (orgCanonical, catalogStatus, catalogOwn, trusted, pagination, isTemplate) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({
    organization_canonical: orgCanonical,
    service_catalog_status: catalogStatus,
    service_catalog_own: catalogOwn,
    service_catalog_trusted: trusted,
    service_catalog_template: isTemplate,
    ...pagination,
  }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalogs`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getStatus */
const getStatus = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/status`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/listNotifications */
const listNotifications = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    '/user/notifications',
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#opetation/listWatchRules */
const listWatchRules = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/user/watch_rules`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/createWatchRule */
const createWatchRule = (...params) => async (data) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    '/user/watch_rules',
    { body: data },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTeam */
const getTeam = (...params) => async (orgCanonical, teamCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/teams/${teamCanonical}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTeamMember */
const getTeamMember = (...params) => async (orgCanonical, teamCanonical, id) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/teams/${teamCanonical}/members/${id}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTeamMembers */
const getTeamMembers = (...params) => async (orgCanonical, teamCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/teams/${teamCanonical}/members`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTeams */
const getTeams = (...params) => async (orgCanonical, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/teams`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTerraformProvider */
const getTerraformProvider = (...params) => async ({ orgCanonical, providerId }) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/code_generation/terraform/providers/${providerId}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTerraformProviderDataSource */
const getTerraformProviderDataSource = (...params) => async ({ orgCanonical, providerId, dataSourceId }) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/code_generation/terraform/providers/${providerId}/data_sources/${dataSourceId}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTerraformProviderDataSources */
const getTerraformProviderDataSources = (...params) => async ({ orgCanonical, providerId }, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/code_generation/terraform/providers/${providerId}/data_sources`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTerraformProviderResource */
const getTerraformProviderResource = (...params) => async ({ orgCanonical, providerId, resourceId }) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/code_generation/terraform/providers/${providerId}/resources/${resourceId}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTerraformProviderResources */
const getTerraformProviderResources = (...params) => async ({ orgCanonical, providerId }, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/code_generation/terraform/providers/${providerId}/resources`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getTerraformProviders */
const getTerraformProviders = (...params) => async ({ orgCanonical }, pagination) => {
  const [authRequest, ...handlers] = params
  const query = _.pickBy({ ...pagination }, (item) => !_.$isEmpty(item))
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/code_generation/terraform/providers`,
    _.$isEmpty(query) ? null : { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getUserAccount */
const getUserAccount = (...params) => async () => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    '/user',
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/getWorkers */
const getWorkers = (...params) => async (orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/workers`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/inviteUserToOrgMember */
const inviteUserToOrgMember = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/members`,
    { body },
    statusFromHandlers(handlers, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/login */
const login = (...params) => async ({ username, email, password, orgCanonical }) => {
  const [publicRequest] = params
  const body = { username, email, password }
  if (orgCanonical) body.organization_canonical = orgCanonical
  const res = await publicRequest(
    POST,
    '/user/login',
    { body },
    [401, 403, 422],
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/emailAuthenticationVerification */
const emailAuthenticationVerification = (...params) => async (token, orgCanonical) => {
  const [publicRequest] = params
  const query = orgCanonical ? { organization_canonical: orgCanonical } : null
  const res = await publicRequest(
    PUT,
    `/user/email/authentication/${token}`,
    query,
    [401, 422],
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/passwordResetReq */
const passwordResetReq = (...params) => async (email) => {
  const [publicRequest] = params
  const res = await publicRequest(
    POST,
    '/user/reset_password',
    { body: { email } },
    [422],
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/passwordResetUpdate */
const passwordResetUpdate = (...params) => async (token, password) => {
  const [publicRequest, ...handlers] = params
  const body = { token, password }
  const res = await publicRequest(
    PUT,
    '/user/reset_password',
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/pauseJob */
const pauseJob = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobCanonical}/pause`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/pausePipeline */
const pausePipeline = (...params) => async (orgCanonical, projectCanonical, pipelineName) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/pause`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/pinResourceVersion */
const pinResourceVersion = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName, versionId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/versions/${versionId}/pin`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/refreshServiceCatalogSource */
const refreshServiceCatalogSource = (...params) => async (orgCanonical, serviceCatalogSourceCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/service_catalog_sources/${serviceCatalogSourceCanonical}/refresh`,
    null,
    statusFromHandlers(handlers, 409, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/refreshToken */
const refreshToken = (...params) => async (query) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    '/user/refresh_token',
    { query },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/removeOrgMember */
const removeOrgMember = (...params) => async (orgCanonical, id) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/members/${id}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/rerunBuild */
const rerunBuild = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobName, buildId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobName}/builds/${buildId}`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/resendInvitation */
const resendInvitation = (...params) => async (orgCanonical, inviteId) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/members/${inviteId}/resend`,
    null,
    statusFromHandlers(handlers, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/resourceSetPinComment */
const resourceSetPinComment = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName, comment) => {
  const [authRequest, ...handlers] = params
  const body = { pin_comment: comment }
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/pin_comment`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/retryProjectImport */
const retryProjectImport = (...params) => async (orgCanonical, projectCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/import/retry`,
    null,
    statusFromHandlers(handlers, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/retryServiceCatalogImport */
const retryServiceCatalogImport = (...params) => async (orgCanonical, stackRef) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/import/retry`,
    null,
    statusFromHandlers(handlers, 422, 404, 500),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/signUp */
const signUp = (...params) => async (body) => {
  const [publicRequest] = params
  const res = await publicRequest(
    POST,
    '/user',
    { body },
    [422],
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/signUpAWSMarketplace */
const signUpAWSMarketplace = (...params) => async (body) => {
  const [publicRequest] = params
  const res = await publicRequest(
    POST,
    '/user/aws_marketplace',
    { body },
    [422],
  )
  return jsonBodyParser(res)
}

/** https://docs.staging.cycloid.io/api/index.html#operation/terraformJSONToHCL */
const terraformJSONToHCL = (...params) => async ({ orgCanonical, configObj }) => {
  const [authRequest, ...handlers] = params
  const body = { config: configObj }
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/code_generation/terraform/jsontohcl`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/terraformValidate */
const terraformValidate = (...params) => async (orgCanonical, config) => {
  const [authRequest, ...handlers] = params
  const body = { config }
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/code_generation/terraform/validate`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/unassignMemberFromTeam */
const unassignMemberFromTeam = (...params) => async (orgCanonical, teamCanonical, id) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    DELETE,
    `/organizations/${orgCanonical}/teams/${teamCanonical}/members/${id}`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/unpauseJob */
const unpauseJob = (...params) => async (orgCanonical, projectCanonical, pipelineName, jobCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/jobs/${jobCanonical}/unpause`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/unpausePipeline */
const unpausePipeline = (...params) => async (orgCanonical, projectCanonical, pipelineName) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/unpause`,
    null,
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/unpinResource */
const unpinResource = (...params) => async (orgCanonical, projectCanonical, pipelineName, resourceName) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineName}/resources/${resourceName}/unpin`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateApiKey */
const updateAPIKey = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body)
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/api_keys/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateAppearance */
const updateAppearance = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/appearances/${body.canonical}`,
    { body },
    statusFromHandlers(handlers),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateConfigRepository */
const updateConfigRepository = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/config_repositories/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateCredential */
const updateCredential = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body)
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/credentials/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateExternalBackend */
const updateExternalBackend = (...params) => async (orgCanonical, externalBackendId, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/external_backends/${externalBackendId}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateInfraPolicy */
const updateInfraPolicy = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body)
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/infra_policies/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateKpi */
const updateKPI = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/kpis/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateOrg */
const updateOrg = (...params) => async (body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateOrgMember */
const updateOrgMember = (...params) => async (orgCanonical, username, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/members/${username}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateOrgRole */
const updateOrgRole = (...params) => async (orgCanonical, roleCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/roles/${roleCanonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updatePipeline */
const updatePipeline = (...params) => async (orgCanonical, projectCanonical, pipelineCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/pipelines/${pipelineCanonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateProject */
const updateProject = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body)
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 409, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/#tag/Organization-projects/operation/updateEnvironment */
const updateProjectEnvironment = (...params) => async (orgCanonical, projectCanonical, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/projects/${projectCanonical}/environments/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateServiceCatalog */
const updateServiceCatalog = (...params) => async (orgCanonical, stackRef, body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateServiceCatalogSource */
const updateServiceCatalogSource = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body)
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/service_catalog_sources/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 409, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateServiceCatalogTerraform */
const updateServiceCatalogTerraform = (...params) => async (orgCanonical, stackRef, useCaseCanonical, config) => {
  const [authRequest, ...handlers] = params
  const body = { config }
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/${useCaseCanonical}/terraform`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateServiceCatalogTerraformDiagram */
const updateServiceCatalogTerraformDiagram = (...params) => async ({ orgCanonical, stackRef, diagram: body }) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/terraform/diagram`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateTeam */
const updateTeam = (...params) => async (orgCanonical, body) => {
  const [authRequest, ...handlers] = params
  await convertOwnerToUsername(body)
  const res = await authRequest(
    PUT,
    `/organizations/${orgCanonical}/teams/${body.canonical}`,
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateUserAccount */
const updateUserAccount = (...params) => async (body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    '/user',
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/updateUserGuide */
const updateUserGuide = (...params) => async (body) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    PUT,
    '/user/guide',
    { body },
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/validateServiceCatalogDependencies */
const validateServiceCatalogDependencies = (...params) => async (stackRef, orgCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    GET,
    `/organizations/${orgCanonical}/service_catalogs/${stackRef}/dependencies/validate`,
    null,
    statusFromHandlers(handlers, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/** https://docs.staging.cycloid.io/api/index.html#operation/validateServiceCatalogSource */
const validateServiceCatalogSource = (...params) => async (orgCanonical, catalogRepositoryCanonical) => {
  const [authRequest, ...handlers] = params
  const res = await authRequest(
    POST,
    `/organizations/${orgCanonical}/service_catalog_sources/${catalogRepositoryCanonical}/validate`,
    null,
    statusFromHandlers(handlers, 409, 422),
  )
  return jsonBodyParser(await applyHandlers(res, handlers))
}

/* eslint-disable no-unused-vars */
/** Log mocked endpoint in dev module, and wait for pauseDuration
 *
 * @param {string}    endpointName
 * @param {object=}   options
 * @param {number=}   options.pauseDuration  _default: 1000_
 */
async function logMockedEndpoint (endpointName, { pauseDuration = 1000 } = {}) {
  if (store.state.dev.showDevThings) store.commit('dev/ADD_MOCKED_ENDPOINT', endpointName)
  await _.$pause(pauseDuration)
}
/* eslint-enable no-unused-vars */

/** Wraps the youdeploy-api-http-client~createHTTPClient method to handle the auto refresh token
 * automatically when the API responds with such requirement.
 *
 * @param {Object}    store                 - Vuex store instance
 * @param {Function}  authRequest           - The method to wrap authenticated API requests
 * @param {Handler}   handle401Unauthenticated - The 401 response handler
 *
 * @returns {Function}                      - A function which has the same signature as the youdeploy-api-http-client~HTTPClient method.
 */
function authReqWithAutoRefreshToken (store, authRequest, router) {
  return async function (method, path, bodyAndQuery = {}, acceptedStatuses = []) {
    let res = await authRequest(method, path, bodyAndQuery, [403, ...acceptedStatuses])

    const body = await jsonBodyParser(res)
    const needToRefreshToken = body?.errors?.[0]?.code === 'UnauthorizedRefreshToken'
    const requireOrganizationMFA = (body?.errors?.[0]?.code === 'RequireOrganizationMFA') ? body.errors : null

    if (needToRefreshToken) {
      await store.dispatch('auth/REFRESH_TOKEN')
      res = await authRequest(method, path, bodyAndQuery, [403, ...acceptedStatuses])
    }

    if (requireOrganizationMFA) {
      console.info(requireOrganizationMFA)
      await store.commit('SET_ERRORS', { key: 'requireOrganizationMFA', errors: requireOrganizationMFA })
      router.push({ name: 'userSecurity' }).catch(() => { /* silenced */ })
    }

    return res
  }
}

/** Create a handler for 500 status codes */
function makeHandler500UnexpectedError () {
  const handler = (response) => {
    if (response.status !== handler.status) return response

    return null
  }

  handler.status = 500
  return handler
}

/** Create a handler for 401 status codes */
function makeHandler401Unauthenticated (router) {
  const handler = (response) => {
    if (response.status !== handler.status) return response

    router.replace({ name: 'logout' }).catch(() => { /* silenced */ })
    return null
  }

  handler.status = 401
  return handler
}

/** Create a handler for 404 status codes, redirects to "notFound" */
function makeHandler404NotFound (router) {
  const handler = (response) => {
    if (response.status !== handler.status) return response

    const { path, meta } = _.get(router, 'currentRoute')
    const { backRouteTo } = _.cloneDeep(meta)
    router.replace({ name: 'notFound', params: { 0: path, backRouteTo } })
    return null
  }

  handler.status = 404
  return handler
}

/** Create a handler for 403 status codes, redirects to "forbidden" */
function makeHandler403Forbidden (router) {
  const handler = (response) => {
    if (response.status !== handler.status) return response

    const { path, meta } = _.get(router, 'currentRoute')
    const { backRouteTo, permissionsKey } = _.cloneDeep(meta)
    router.replace({ name: 'forbidden', params: { 0: path, backRouteTo, permissionsKey } })
    return null
  }

  handler.status = 403
  return handler
}

function makeHandler409Conflict () {
  const handler = (response) => response

  handler.status = 409
  return handler
}

/** Get a list of all the statuses from treated handlers
 *
 * @param {Handler[]}   handlers  - List of handlers.
 * @param {Number[]}    [status]  - Additional status to add to the list.
 * @returns {Number[]}            - The list with all the statuses codes of the handlers,
 *                                  plus the ones provided by parameter.
 */
function statusFromHandlers (handlers, ...status) {
  return [...handlers.map((h) => h.status), ...status]
}

/** Helper function which executes a list of handlers stopping at the first one
 * It handles the response, otherwise it returns the response passed by parameter.
 *
 * @param {WebAPI~Response} res       - The response to pass to the handlers.
 * @param {Handler[]}       handlers  - List of handlers to execute in order.
 * @returns {?Response}               - The response passed by parameter if any handler treated it,
 *                                      otherwise null.
 */
async function applyHandlers (res, handlers) {
  for (const h of handlers) {
    if (res === null) {
      break
    }

    res = await h(res)
  }

  return res
}

async function jsonBodyParser (res) {
  let body = null
  try {
    body = await res?.clone()?.json()
  } catch (error) {}
  return body ?? null
}

/* Returns a snake_cased object for use with the api */
function getPagination (index, size) {
  return {
    page_index: index,
    page_size: size,
  }
}

export {
  getPagination as pagination,
}

/** Create an object which has methods to perform any of the YD API Spec operations.
 * The methods are named as the operation ID field used in the Swagger Spec.
 * The object may contain other methods to do API calls combined together.
 *
 * @param {httpClient} httpClient - Instance created by
 *      youdeploy-api-http-client~createHTTPClient
 * @param {Object} store - Vuex store instance
 * @param {Object} i18n - Instance of vue-i18n
 * @param {Object} router - Instance of vue-router
 * @returns {Object} - And object which contains the methods mentioned above.
 *              Each method receive the path, query and/or body which the API
 *              operation requires.
 *
 * @see [youdeploy-api-http-client]{@link ./youdeploy-api-http-client.js}
 */
export default function createAPI (httpClient, store, _i18n, router) {
  const handle500UnexpectedError = makeHandler500UnexpectedError()
  const handle401Unauthenticated = makeHandler401Unauthenticated(router)
  const handle404NotFound = makeHandler404NotFound(router)
  const handle403Forbidden = makeHandler403Forbidden(router)
  const handle409Conflict = makeHandler409Conflict()

  const { publicRequest } = httpClient
  const authRequest = authReqWithAutoRefreshToken(store, httpClient.authRequest, router)

  const base = [authRequest, handle401Unauthenticated]
  const params = {
    /**
      * For non GET requests
      * @param authRequest
      * @param handle401Unauthenticated
      * @param handle404NotFound
      */
    default: [...base, handle404NotFound],

    /**
       * For GET requests
       * - extends base params, adds handlers for 403 and 404 responses
       * @param authRequest
       * @param handle401Unauthenticated
       * @param handle404NotFound
       * @param handle403Forbidden
       */
    GET: [...base, handle404NotFound, handle403Forbidden],

    /**
       * For requests where we want to handle the error response manually (no 404 page)
       * - extends base params, adds handler for 403 responses
       * @param authRequest
       * @param handle401Unauthenticated
       * @param handle403Forbidden
       */
    NO_REDIRECT: [...base, handle403Forbidden],

    /**
       * For requests where we don't want to show the global error for 500 responses
       * - does not extend base params, as handle401Unauthenticated redirects us to login page
       * - adds handler for 500 responses
       * @param authRequest
       * @param handle500UnexpectedError
       */
    NO_UNEXPECTED_ERROR: [authRequest, handle500UnexpectedError],

    /**
       * For requests where we need to show conflicts (errors) to the user, that stop the action succeeding.
       * - adds handler for 409 responses
       * @param authRequest
       * @param handle401Unauthenticated
       * @param handle500UnexpectedError
       */
    SHOW_CONFLICTS: [...base, handle409Conflict],

    /**
       * Public requests: usually for use in pages where User is not signed in yet
       * @param publicRequest
       */
    public: [publicRequest],
  }

  return {
    // APP
    getAppConfig: getAppConfig(...params.GET),
    getAppVersion: getAppVersion(...params.GET),
    getCountries: getCountries(...params.GET),
    getStatus: getStatus(...params.GET),
    listNotifications: listNotifications(...params.GET),
    listWatchRules: listWatchRules(...params.GET),
    createWatchRule: createWatchRule(...params.default),

    // AUTH, LOGIN & USER
    createOAuthUser: createOAuthUser(...params.public),
    deleteUserAccount: deleteUserAccount(...params.NO_UNEXPECTED_ERROR),
    getOAuthUser: getOAuthUser(...params.public),
    getUserAccount: getUserAccount(...params.GET),
    login: login(...params.public),
    emailAuthenticationVerification: emailAuthenticationVerification(...params.public),
    passwordResetReq: passwordResetReq(...params.public),
    passwordResetUpdate: passwordResetUpdate(...params.public),
    refreshToken: refreshToken(...params.GET),
    resendVerifyEmail: emailVerificationResend(...params.default),
    signUp: signUp(...params.public),
    signUpAWSMarketplace: signUpAWSMarketplace(...params.public),
    updateUserAccount: updateUserAccount(...params.default),
    updateUserGuide: updateUserGuide(...params.default),
    verifyEmail: emailVerification(...params.NO_REDIRECT),

    // ORGANIZATION
    createOrg: createOrg(...params.default),
    createOrgChild: createOrgChild(...params.default),
    deleteOrg: deleteOrg(...params.SHOW_CONFLICTS),
    getOrg: getOrg(...params.GET),
    getOrgs: getOrgs(...params.GET),
    getOrgSummary: getOrgSummary(...params.NO_REDIRECT),
    updateOrg: updateOrg(...params.default),

    // ORGANIZATION------APPEARANCE
    createAppearance: createAppearance(...params.default),
    deleteAppearance: deleteAppearance(...params.default),
    getActiveAppearance: getActiveAppearance(...params.GET),
    getAppearance: getAppearance(...params.GET),
    getDefaultAppearance: getDefaultAppearance(...params.GET),
    listAppearances: listAppearances(...params.GET),
    updateAppearance: updateAppearance(...params.default),

    // ORGANIZATION------API KEY
    createAPIKey: createAPIKey(...params.default),
    deleteAPIKey: deleteAPIKey(...params.default),
    getAPIKey: getAPIKey(...params.GET),
    getAPIKeys: getAPIKeys(...params.GET),
    updateAPIKey: updateAPIKey(...params.default),

    // ORGANIZATION------ANCESTORS
    getOrgAncestors: getOrgAncestors(...params.GET),

    // ORGANIZATION------CLOUD PROVIDERS
    getCloudProviders: getCloudProviders(...params.GET),

    // ORGANIZATION------CHILDREN
    getOrgChildren: getOrgChildren(...params.default),

    // ORGANIZATION------CATALOG REPOSITORY
    createServiceCatalogSource: createServiceCatalogSource(...params.default),
    deleteServiceCatalogSource: deleteServiceCatalogSource(...params.default),
    getRepoBranches: getRepoBranches(...params.NO_REDIRECT),
    getServiceCatalogSource: getServiceCatalogSource(...params.GET),
    getServiceCatalogSources: getServiceCatalogSources(...params.GET),
    refreshServiceCatalogSource: refreshServiceCatalogSource(...params.default),
    updateServiceCatalogSource: updateServiceCatalogSource(...params.default),
    validateServiceCatalogSource: validateServiceCatalogSource(...params.NO_REDIRECT),

    // ORGANIZATION------CONFIG REPOSITORY
    createConfigRepository: createConfigRepository(...params.default),
    createConfigRepositoryConfig: createConfigRepositoryConfig(...params.default),
    deleteConfigRepository: deleteConfigRepository(...params.default),
    getConfigRepositories: getConfigRepositories(...params.GET),
    getConfigRepository: getConfigRepository(...params.GET),
    updateConfigRepository: updateConfigRepository(...params.default),

    // ORGANIZATION------COST
    costEstimateForms: costEstimateForms(...params.default),
    getOrgCost: getOrgCost(...params.GET),
    getOrgCostServices: getOrgCostServices(...params.GET),

    // ORGANIZATION------CLOUD COST MANAGEMENT
    createCloudCostManagementAccount: createCloudCostManagementAccount(...params.default),
    createCloudCostManagementAccountChild: createCloudCostManagementAccountChild(...params.default),
    deleteCloudCostManagementAccount: deleteCloudCostManagementAccount(...params.default),
    updateCloudCostManagementAccount: updateCloudCostManagementAccount(...params.default),
    getCloudCostManagementAccount: getCloudCostManagementAccount(...params.GET),
    getCloudCostManagementAccounts: getCloudCostManagementAccounts(...params.GET),
    getCloudCostManagementAccountsHasParent: getCloudCostManagementAccountsHasParent(...params.NO_REDIRECT),
    getCloudCostManagementTagMapping: getCloudCostManagementTagMapping(...params.GET),
    updateCloudCostManagementTagMapping: updateCloudCostManagementTagMapping(...params.default),
    getCloudCostManagementProvider: getCloudCostManagementProvider(...params.GET),
    getCloudCostManagementDashboard: getCloudCostManagementDashboard(...params.GET),

    // ORGANIZATION------CREDENTIAL
    createCredential: createCredential(...params.default),
    deleteCredential: deleteCredential(...params.default),
    getCredential: getCredential(...params.GET),
    listCredentials: listCredentials(...params.GET),
    getCredentialOptions: getCredentialOptions(...params.GET),
    getCycloidCredential: getCycloidCredential(...params.NO_REDIRECT),
    updateCredential: updateCredential(...params.default),

    // ORGANIZATION------ENVIRONMENT
    getEnvironments: getEnvironments(...params.GET),

    // ORGANIZATION------EVENTS
    getOrgEvents: getOrgEvents(...params.GET),
    getOrgEventsTags: getOrgEventsTags(...params.GET),

    // ORGANIZATION------EXTERNAL BACKEND
    createExternalBackend: createExternalBackend(...params.default),
    deleteExternalBackend: deleteExternalBackend(...params.default),
    getExternalBackend: getExternalBackend(...params.default),
    getExternalBackends: getExternalBackends(...params.NO_REDIRECT),
    updateExternalBackend: updateExternalBackend(...params.default),

    // ORGANIZATION------INFRAIMPORT
    createInfraImport: createInfraImport(...params.NO_REDIRECT),
    getInfraImportResource: getInfraImportResource(...params.NO_REDIRECT),
    getInfraImportResources: getInfraImportResources(...params.NO_REDIRECT),
    getProjectImport: getProjectImport(...params.NO_REDIRECT),
    getServiceCatalogImport: getServiceCatalogImport(...params.NO_REDIRECT),
    retryProjectImport: retryProjectImport(...params.NO_REDIRECT),
    retryServiceCatalogImport: retryServiceCatalogImport(...params.NO_REDIRECT),

    // ORGANIZATION------INFRAPOLICY
    createInfraPolicy: createInfraPolicy(...params.default),
    deleteInfraPolicy: deleteInfraPolicy(...params.default),
    getInfraPolicies: getInfraPolicies(...params.GET),
    getInfraPolicy: getInfraPolicy(...params.GET),
    updateInfraPolicy: updateInfraPolicy(...params.default),

    // ORGANIZATION------INVENTORY
    getInventoryResources: getInventoryResources(...params.GET),

    // ORGANIZATION------INVITATION
    deleteInvitation: deleteInvitation(...params.default),
    getInvitations: getInvitations(...params.GET),
    getPendingInvitation: getPendingInvitation(...params.GET),
    resendInvitation: resendInvitation(...params.default),

    // ORGANIZATION------KPI
    getKPI: getKPI(...params.GET),
    getKPIs: getKPIs(...params.GET),
    createKPI: createKPI(...params.NO_REDIRECT),
    createKPIFavorite: createKPIFavorite(...params.default),
    updateKPI: updateKPI(...params.default),
    deleteKPI: deleteKPI(...params.default),
    deleteKPIFavorite: deleteKPIFavorite(...params.default),

    // ORGANIZATION------LICENCE & SUBSCRIPTION
    activateLicence: activateLicence(...params.default),
    createSubscription: createSubscription(...params.default),
    getLicence: getLicence(...params.GET),

    // ORGANIZATION------MEMBER
    getOrgMember: getOrgMember(...params.GET),
    getOrgMembers: getOrgMembers(...params.GET),
    inviteUserToOrgMember: inviteUserToOrgMember(...params.NO_REDIRECT),
    removeOrgMember: removeOrgMember(...params.default),
    updateOrgMember: updateOrgMember(...params.default),

    // ORGANIZATION------PERMISSIONS
    getPolicies: getPolicies(...params.GET),
    canDo: canDo(...params.NO_UNEXPECTED_ERROR),

    // ORGANIZATION------PROJECT
    createProject: createProject(...params.NO_REDIRECT),
    createProjectFavorite: createProjectFavorite(...params.default),
    deleteProject: deleteProject(...params.default),
    deleteProjectFavorite: deleteProjectFavorite(...params.default),
    getProject: getProject(...params.GET),
    getProjects: getProjects(...params.GET),
    updateProject: updateProject(...params.NO_REDIRECT),

    // ORGANIZATION------PROJECT------ENVIRONMENT
    createProjectEnvironment: createProjectEnvironment(...params.default),
    updateProjectEnvironment: updateProjectEnvironment(...params.default),
    deleteProjectEnvironment: deleteProjectEnvironment(...params.default),
    getProjectEnvironmentConfig: getProjectEnvironmentConfig(...params.GET),
    getProjEnvLogEntries: getProjEnvLogEntries(...params.GET),
    getProjEnvLogSources: getProjEnvLogSources(...params.NO_REDIRECT),
    getProjInfrastructure: getProjInfrastructure(...params.NO_REDIRECT),

    // ORGANIZATION------PROJECT------PIPELINE
    createPipeline: createPipeline(...params.default),
    deletePipeline: deletePipeline(...params.default),
    diffPipeline: diffPipeline(...params.default),
    getPipeline: getPipeline(...params.GET),
    getPipelineConfig: getPipelineConfig(...params.GET),
    getPipelineResources: getPipelineResources(...params.GET),
    getPipelines: getPipelines(...params.NO_REDIRECT),
    getPipelineVariables: getPipelineVariables(...params.NO_REDIRECT),
    getProjectPipelines: getProjectPipelines(...params.GET),
    syncedPipeline: syncedPipeline(...params.GET),
    pausePipeline: pausePipeline(...params.default),
    unpausePipeline: unpausePipeline(...params.default),
    updatePipeline: updatePipeline(...params.default),

    // ORGANIZATION------PROJECT------PIPELINE------BUILD
    abortBuild: abortBuild(...params.default),
    createBuild: createBuild(...params.default),
    getBuild: getBuild(...params.GET),
    getBuildPlan: getBuildPlan(...params.GET),
    getBuildPreparation: getBuildPreparation(...params.GET),
    getBuildResources: getBuildResources(...params.GET),
    getBuilds: getBuilds(...params.GET),
    getBuildsWithVersionAsInput: getBuildsWithVersionAsInput(...params.GET),
    getBuildsWithVersionAsOutput: getBuildsWithVersionAsOutput(...params.GET),
    rerunBuild: rerunBuild(...params.default),

    // ORGANIZATION------PROJECT------PIPELINE------JOB
    clearTaskCache: clearTaskCache(...params.default),
    getJob: getJob(...params.GET),
    getJobs: getJobs(...params.GET),
    pauseJob: pauseJob(...params.default),
    unpauseJob: unpauseJob(...params.default),

    // ORGANIZATION------PROJECT------PIPELINE------RESOURCE
    checkResource: checkResource(...params.default),
    disableResourceVersion: disableResourceVersion(...params.default),
    enableResourceVersion: enableResourceVersion(...params.default),
    getPipelineResource: getPipelineResource(...params.GET),
    getResourceVersions: getResourceVersions(...params.GET),
    pinResourceVersion: pinResourceVersion(...params.default),
    resourceSetPinComment: resourceSetPinComment(...params.default),
    unpinResource: unpinResource(...params.default),

    // ORGANIZATION------PROJECT------STACK FORMS
    createFormsConfig: createFormsConfig(...params.default),
    validateFormsFile: validateFormsFile(...params.default),

    // ORGANIZATION------QUOTA
    createQuota: createQuota(...params.default),
    getQuota: getQuota(...params.GET),
    listQuotas: listQuotas(...params.GET),
    updateQuota: updateQuota(...params.default),
    deleteQuota: deleteQuota(...params.default),
    getQuotaUsage: getQuotaUsage(...params.GET),

    // ORGANIZATION------RESOURCE
    createResource: createResource(...params.default),
    getResource: getResource(...params.GET),
    deleteResource: deleteResource(...params.default),
    updateResource: updateResource(...params.default),
    listResourcesByLabel: listResourcesByLabel(...params.GET),

    // ORGANIZATION------RESOURCE POOL
    listResourceLabels: listResourceLabels(...params.GET),
    listResourceTypes: listResourceTypes(...params.GET),
    createResourcePool: createResourcePool(...params.default),
    listResourcePools: listResourcePools(...params.GET),
    getResourcePool: getResourcePool(...params.GET),
    deleteResourcePool: deleteResourcePool(...params.default),
    updateResourcePool: updateResourcePool(...params.default),
    getEnvQuotaRequirement: getEnvQuotaRequirement(...params.GET),

    // ORGANIZATION------ROLE
    createOrgRole: createOrgRole(...params.default),
    deleteOrgRole: deleteOrgRole(...params.default),
    getOrgRole: getOrgRole(...params.GET),
    getOrgRoles: getOrgRoles(...params.GET),
    updateOrgRole: updateOrgRole(...params.default),

    // ORGANIZATION------RUNNING INFRA
    getOrgRunningInfraAWS: getOrgRunningInfraAWS(...params.GET),
    getOrgRunningInfraAWSInstances: getOrgRunningInfraAWSInstances(...params.GET),

    // ORGANIZATION------STACK
    createServiceCatalog: createServiceCatalog(...params.default),
    createServiceCatalogFromTemplate: createServiceCatalogFromTemplate(...params.default),
    deleteServiceCatalog: deleteServiceCatalog(...params.SHOW_CONFLICTS),
    getFormsValuesRef: getFormsValuesRef(...params.default),
    getServiceCatalog: getServiceCatalog(...params.GET),
    getServiceCatalogConfig: getServiceCatalogConfig(...params.NO_REDIRECT),
    getServiceCatalogs: getServiceCatalogs(...params.GET),
    updateServiceCatalog: updateServiceCatalog(...params.default),
    updateServiceCatalogTerraform: updateServiceCatalogTerraform(...params.default),
    validateServiceCatalogDependencies: validateServiceCatalogDependencies(...params.GET),

    // ORGANIZATION------TEAM
    createTeam: createTeam(...params.default),
    deleteTeam: deleteTeam(...params.default),
    getTeam: getTeam(...params.GET),
    getTeams: getTeams(...params.GET),
    updateTeam: updateTeam(...params.default),

    // ORGANIZATION------TEAM------MEMBER
    assignMemberToTeam: assignMemberToTeam(...params.default),
    getTeamMember: getTeamMember(...params.GET),
    getTeamMembers: getTeamMembers(...params.GET),
    unassignMemberFromTeam: unassignMemberFromTeam(...params.default),

    // ORGANIZATION------TERRAFORM
    getServiceCatalogTerraform: getServiceCatalogTerraform(...params.GET),
    getServiceCatalogTerraformDiagram: getServiceCatalogTerraformDiagram(...params.GET),
    getTerraformProvider: getTerraformProvider(...params.GET),
    getTerraformProviderDataSource: getTerraformProviderDataSource(...params.GET),
    getTerraformProviderDataSources: getTerraformProviderDataSources(...params.GET),
    getTerraformProviderResource: getTerraformProviderResource(...params.GET),
    getTerraformProviderResources: getTerraformProviderResources(...params.GET),
    getTerraformProviders: getTerraformProviders(...params.GET),
    terraformJSONToHCL: terraformJSONToHCL(...params.default),
    terraformValidate: terraformValidate(...params.default),
    updateServiceCatalogTerraformDiagram: updateServiceCatalogTerraformDiagram(...params.default),

    // ORGANIZATION------WORKERS
    getWorkers: getWorkers(...params.GET),
  }
}
