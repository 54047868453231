const { VUE_APP_DOCS_URL } = process.env

const api = {
  index: `${VUE_APP_DOCS_URL}/api.html`,
}

const apiKeys = {
  index: `${VUE_APP_DOCS_URL}/manage/api-keys.html`,
}

const catalogRepositories = {
  create: `${VUE_APP_DOCS_URL}/deploy/stack/stack-definition.html#creating-and-updating-a-catalog-repository`,
}

const cloudCostManagement = {
  index: `${VUE_APP_DOCS_URL}/manage/cloud-cost-management/overview.html`,
  cloudCarbonFootprint: 'https://www.cloudcarbonfootprint.org/docs/methodology',
}

const infraImport = {
  index: `${VUE_APP_DOCS_URL}/deploy/infraimport.html`,
  createAStack: `${VUE_APP_DOCS_URL}/deploy/create-my-first-stack/intro.html`,
}

const infraPolicies = {
  index: `${VUE_APP_DOCS_URL}/govern/infrapolicy.html`,
  openPolicyLanguage: `https://www.openpolicyagent.org/docs/latest/policy-language/`,
}

const inventory = {
  index: `${VUE_APP_DOCS_URL}/manage/inventory.html`,
}

const permissions = {
  organization: `${VUE_APP_DOCS_URL}/govern/govern.html#organizations`,
  'organization:api_key': `${VUE_APP_DOCS_URL}/manage/api-keys.html`,
  'organization:child': `${VUE_APP_DOCS_URL}/govern/govern.html#organizations`,
  'organization:cloud_provider': `${VUE_APP_DOCS_URL}#`,
  'organization:config_repository': `${VUE_APP_DOCS_URL}/get-started/configuration.html#config-repositories`,
  'organization:cost_explorer': `${VUE_APP_DOCS_URL}/manage/cloud-cost-management/overview.html`,
  'organization:credential': `${VUE_APP_DOCS_URL}/manage/credentials/credentials.html`,
  'organization:environment': `${VUE_APP_DOCS_URL}/manage/project.html#environments`,
  'organization:external_backend': `${VUE_APP_DOCS_URL}/manage/infraview.html#configuration`,
  'organization:infra_policy': `${VUE_APP_DOCS_URL}/govern/infrapolicy.html`,
  'organization:inventory_resource': `${VUE_APP_DOCS_URL}/govern/inventory.html`,
  'organization:invitation': `${VUE_APP_DOCS_URL}/govern/govern.html#organization-level`,
  'organization:kpi': `${VUE_APP_DOCS_URL}/manage/project.html#kpis`,
  'organization:member': `${VUE_APP_DOCS_URL}/govern/govern.html#user-management#user-level`,
  'organization:pipeline': `${VUE_APP_DOCS_URL}/manage/pipeline/pipeline.html`,
  'organization:policy': `${VUE_APP_DOCS_URL}/govern/permissions.html#policies`,
  'organization:project': `${VUE_APP_DOCS_URL}/manage/project.html`,
  'organization:resource_pool': `${VUE_APP_DOCS_URL}/govern/quotas.html#resource-pools`,
  'organization:quota': `${VUE_APP_DOCS_URL}/govern/quotas.html#quotas-2`,
  'organization:role': `${VUE_APP_DOCS_URL}/govern/permissions.html#roles`,
  'organization:service_catalog_source': `${VUE_APP_DOCS_URL}/deploy/stack/stack-definition.html#creating-and-updating-a-catalog-repository`,
  'organization:service_catalog': `${VUE_APP_DOCS_URL}/deploy/stack/stack.html`,
  'organization:team:member': `${VUE_APP_DOCS_URL}/govern/permissions.html#teams-level`,
  'organization:team': `${VUE_APP_DOCS_URL}/govern/permissions.html#teams-level`,
}

const project = {
  billing: `${VUE_APP_DOCS_URL}/manage/project.html#billing`,
  environments: `${VUE_APP_DOCS_URL}/manage/project.html#environments`,
  deploy: `${VUE_APP_DOCS_URL}/get-started/deploy.html#create-my-first-project-with-pre-configured-resources`,
  index: `${VUE_APP_DOCS_URL}/manage/project.html`,
  infraView: `${VUE_APP_DOCS_URL}/manage/infraview.html`,
  kpis: `${VUE_APP_DOCS_URL}/manage/project.html#kpis`,
  logs: {
    index: `${VUE_APP_DOCS_URL}/manage/project.html#logs`,
    syntaxExamples: {
      aws: 'https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/FilterAndPatternSyntax',
      elasticsearch: 'https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-multi-match-query.html',
    },
  },
}

const quotas = {
  index: `${VUE_APP_DOCS_URL}/govern/quotas.html`,
  resources: `${VUE_APP_DOCS_URL}/govern/quotas.html#resources`,
  resourcePools: `${VUE_APP_DOCS_URL}/govern/quotas.html#resource-pools`,
}

const roles = {
  manualActions: `${VUE_APP_DOCS_URL}/govern/permissions.html#manual-actions`,
  policies: `${VUE_APP_DOCS_URL}/govern/permissions.html#policies`,
}

const stacks = {
  readme: `${VUE_APP_DOCS_URL}/deploy/create-my-first-stack/step2-part1.html#readme-md-optional`,
  overview: `${VUE_APP_DOCS_URL}/immerse/concepts.html#stacks`,
  design: `${VUE_APP_DOCS_URL}/deploy/stack/stack.html#stack`,
  stepByStep: `${VUE_APP_DOCS_URL}/deploy/create-my-first-stack/intro.html`,
}

const terraform = {
  providers: 'https://www.terraform.io/docs/providers',
  state: 'https://www.terraform.io/docs/state',
}

const workers = {
  aws: `${VUE_APP_DOCS_URL}/manage/workers/aws.html`,
  azure: `${VUE_APP_DOCS_URL}/manage/workers/azure.html`,
  azureLink: 'https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fraw.githubusercontent.com%2Fcycloid-community-catalog%2Fstack-external-worker%2Fmaster%2Fextra%2Fazure%2Fexternal-worker-azure-rm-template.json',
  baremetal: `${VUE_APP_DOCS_URL}/manage/workers/baremetal.html`,
  docker: `${VUE_APP_DOCS_URL}/manage/workers/docker.html`,
  flexeng: `${VUE_APP_DOCS_URL}/manage/workers/flexible-engine.html`,
  flexengZip: 'https://cycloid-cloudformation.s3-eu-west-1.amazonaws.com/flexible-engine.zip',
  gcp: `${VUE_APP_DOCS_URL}/manage/workers/gcp.html`,
  gcpSetup: 'https://cloud.google.com/deployment-manager/docs/step-by-step-guide/installation-and-setup',
  overview: `${VUE_APP_DOCS_URL}/manage/workers/workers.html`,
  onPremHelp: `${VUE_APP_DOCS_URL}/onprem/overview.html#cycloid-worker-installation`,
  troubleshooting: `${VUE_APP_DOCS_URL}/manage/workers/workers.html#troubleshooting`,
}

export default {
  api,
  apiKeys,
  catalogRepositories,
  cloudCostManagement,
  infraImport,
  infraPolicies,
  inventory,
  permissions,
  project,
  quotas,
  roles,
  stacks,
  terraform,
  workers,
}
